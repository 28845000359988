import { useQuery } from "@apollo/react-hooks";
import { Dropdown, Input, Menu } from "antd";
import { get, isEmpty } from "lodash";
import { default as React, useContext, useState } from "react";
import { TabContext } from "../../../../../../Context/Tab";
import { ManufacturersQueries } from "../../../../../../shared/graphql/queries";
import { SCREENS, TABS_ACTIONS } from "../../../../../../utils/constants";

const { LINKED_ASSEMBLY_GROUPS } = ManufacturersQueries;

const { Search } = Input;

export default ({ search }) => {
  const { dispatch } = useContext(TabContext);
  const {
    linkingTarget,
    manufacturer,
    model,
    manufacturerBean,
    modelBean,
    motorizationBean,
  } = search;

  const [searchValue, setSearchValue] = useState("");

  const { data } = useQuery(LINKED_ASSEMBLY_GROUPS, {
    variables: {
      where: {
        linkingTargetId: linkingTarget,
      },
    },
  });

  const linkedAssemblyGroup = get(data, "linkedAssemblyGroup.data", []);

  const onSelect = (id, name) => {
    return dispatch({
      type: TABS_ACTIONS.HISTORY_PUSH,
      payload: {
        screen: SCREENS.ARTICLES,
        props: {
          search: {
            name,
            assemblyGroup: { id, name },
            car: { id: linkingTarget },
            manufacturer,
            model,
            manufacturerBean,
            modelBean,
            motorizationBean,
          },
        },
      },
    });
  };

  const filtredAssemblyGroup = linkedAssemblyGroup
    .filter(
      ({ assemblyGroupName }) =>
        searchValue.length >= 4 &&
        assemblyGroupName.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
    )
    .map((famille) =>
      [
        famille.parentNodeId &&
          linkedAssemblyGroup.find(
            ({ assemblyGroupNodeId }) =>
              assemblyGroupNodeId === famille.parentNodeId
          ),
        famille,
      ].filter((a) => a)
    );

  const AssemblyGroupItems = () => {
    return (
      <Dropdown
        visible={!isEmpty(filtredAssemblyGroup)}
        overlay={
          <Menu
            style={{
              height: 220,
              display: "flex",
              flexDirection: "column",
              overflow: "scroll",
              overflowX: "hidden",
              width: 650,
            }}
            className="dropdown_menu"
          >
            {filtredAssemblyGroup.map((item) => {
              if (item.length >= 2) {
                return (
                  <Menu.Item key={item[1].assemblyGroupNodeId}>
                    <div
                      onClick={() => {
                        onSelect(
                          item[1].assemblyGroupNodeId,
                          item[1].assemblyGroupName
                        );
                      }}
                    >
                      <div style={{ cursor: "pointer" }}>
                        <span>
                          {`${item[0].assemblyGroupName} - ${item[1].assemblyGroupName}`}
                        </span>
                      </div>
                    </div>
                  </Menu.Item>
                );
              } else {
                return (
                  <Menu.Item key={item[0].assemblyGroupNodeId}>
                    <div
                      onClick={() => {
                        onSelect(
                          item[0].assemblyGroupNodeId,
                          item[0].assemblyGroupName
                        );
                      }}
                    >
                      <div style={{ cursor: "pointer" }}>
                        <span>{item[0].assemblyGroupName} </span>
                      </div>
                    </div>
                  </Menu.Item>
                );
              }
            })}
          </Menu>
        }
      >
        <div></div>
      </Dropdown>
    );
  };

  return (
    <div>
      <Search
        placeholder="Cherchez une pièce"
        size={"medium"}
        value={searchValue}
        onChange={(_) => {
          return setSearchValue(_.target.value);
        }}
      />
      {searchValue ? <AssemblyGroupItems></AssemblyGroupItems> : null}
    </div>
  );
};
