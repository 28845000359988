import get from "lodash/get";
import isNil from "lodash/isNil";
import React, { useEffect } from "react";
import { useLazyQuery } from "react-apollo";
import { images, links } from "../../../utils/Logos/Cars";
import { ManufacturersQueries } from "../../graphql/queries";
import Heading from "../stories/Heading";

const { MANUFACTURER, MODEL } = ManufacturersQueries;

export default ({ location: { search } }) => {
  // console.log("search", search);
  const { manufacturer: manufacturerId, model: modelId } = search;
  const [getManufacturer, { data: manufacturerData }] =
    useLazyQuery(MANUFACTURER);
  const [getModel, { data: modelData }] = useLazyQuery(MODEL);

  useEffect(() => {
    const get = () => {
      const variables = { where: { id: manufacturerId } };
      getManufacturer({ variables });
    };
    get();
  }, [getManufacturer, manufacturerId]);
  useEffect(() => {
    const get = () => {
      const variables = {
        where: { id: modelId, manufacturer: { id: manufacturerId } },
      };
      getModel({ variables });
    };
    get();
  }, [modelId, manufacturerId, getModel]);

  const manufacturer = get(manufacturerData, "manufacturer", {});
  const model = get(modelData, "model", {});
  const manufacturerName = get(manufacturer, "name", "");
  const modelName = get(model, "name", "");

  return (
    <div className="heading-brand growed">
      <img
        style={{
          maxHeight: "40px",

          marginRight: 5,
          maxWidth: "none",
        }}
        src={
          !isNil(images[manufacturerName])
            ? images[manufacturerName]
            : !isNil(links[manufacturerName])
            ? links[manufacturerName]
            : links.default
        }
        alt={manufacturerName}
      />
      <Heading
        style={{ width: "auto" }}
        title={manufacturerName + (modelName ? " > " + modelName : "")}
        sansIcone={true}
      />
      {modelId && (
        <object
          style={{ maxHeight: 40, marginLeft: 5 }}
          key={modelId}
          data={`https://media.carparts-cat.com/webdata2/kmodthumb/${modelId}.png`}
          type="image/png"
        >
          {/*   <img
            style={{ maxHeight: 60 }}
            src="https://www.freeiconspng.com/uploads/no-image-icon-13.png"
            alt="Modèle"
          /> */}
        </object>
      )}
    </div>
  );
};
