import { useQuery } from "@apollo/react-hooks";
import { Col, List } from "antd";
import cuid from "cuid";
import { dequal } from "dequal";
import forEach from "lodash/forEach";
import groupBy from "lodash/groupBy";
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { QUERY_MDDS } from "../../../../../../shared/graphql/queries/mdd";
import { StoreContext } from "../../../../../../shared/store";
import ArticleCard from "../Widgets/Card";
export default React.memo(
  ({
    articles,
    setVisible,
    setSelectedArticle,
    setAjouterPerteVenteVisible,
  }) => {
    const {
      compact,
      user: { account },
    } = useContext(StoreContext);

    const {
      refetch: fetchMdds,
      data: dataMdds,
      loading: loadingMdds,
    } = useQuery(QUERY_MDDS, {
      variables: {
        mddInput: {
          motCle: "",
        },
      },
    });

    var dataTest = [];

    Object.entries(articles).map(([key, value], index0) => {
      /* 
        "groupBy name",
        groupBy(value, function (item) {
          return item.name.toLowerCase();
        })
      ); */

      var dataTestTemp = groupBy(value, function (item) {
        return item.assemblyGroups.length > 0
          ? item.assemblyGroups[0].name.toLowerCase()
          : item.name;
      });

      forEach(dataTestTemp, function (value0, key0) {
        Object.entries(
          groupBy(dataTestTemp[key0], function (item) {
            return item.brand.name.toLowerCase();
          })
        ).map(([key2, values2], index2) => {
          if (
            Object.entries(dataTest).length > 0 &&
            Object.entries(dataTest)[
              Object.entries(dataTest).length - 1
            ][0].substring(
              0,
              Object.entries(dataTest)[
                Object.entries(dataTest).length - 1
              ][0].lastIndexOf(";;")
            ) === key0
          )
            dataTest[
              Object.entries(dataTest)[Object.entries(dataTest).length - 1][0]
            ].push([key2, values2]);
          else {
            var newKey = key0 + ";;" + cuid();
            dataTest[newKey] = [];
            dataTest[newKey].push([key2, values2]);
          }
        });
      });
    });

    const history = useHistory();
    return (
      <>
        <List
          grid={{ column: 1, multiCol: true }}
          dataSource={Object.entries(dataTest)}
          renderItem={([key, values], index) => {
            return (
              <List.Item key={"list-item " + key + "-" + index}>
                <div className="card_article_group_by_famille">
                  <div className="famille_title">
                    {key.substring(0, key.lastIndexOf(";;")) === "null"
                      ? "Inconnu"
                      : key.substring(0, key.lastIndexOf(";;")).toUpperCase()}
                  </div>

                  {values.map(([key1, values1], index1) => (
                    <div
                      key={key1 + "-" + index1}
                      className={
                        "card_article_group_by_fournisseur" +
                        (index1 === 0 ? " first" : "")
                      }
                    >
                      <Col span={24}>
                        <div className={"brand_title"}>
                          {key1.toUpperCase()}
                        </div>
                      </Col>

                      {values1.map((artilce, index2) => (
                        <Col
                          className={
                            values1.length === index2 + 1
                              ? ""
                              : "not-last-article"
                          }
                          key={`article-col-${artilce.id}-${artilce.code}`}
                          span={24}
                        >
                          <ArticleCard
                            MDDS={dataMdds}
                            mode={!compact ? "Normal" : "Compact"}
                            account={account}
                            article={artilce}
                            history={history}
                            setVisible={setVisible}
                            setSelectedArticle={setSelectedArticle}
                            setAjouterPerteVenteVisible={
                              setAjouterPerteVenteVisible
                            }
                            key={`article-${artilce.id}-${artilce.code}`}
                          />
                        </Col>
                      ))}
                    </div>
                  ))}
                </div>
              </List.Item>
            );
          }}
        />
      </>
    );

    /*  return Object.entries(articles).map(([key, value], index0) => {
      return (
        <>
          {Object.entries(groupBy(value, "brand.name")).map(
            ([key, values], index) => (
              <>
                <Row
                  className={
                    "card_article_group_by_fournisseur" +
                    (index0 === 0 && index === 0 ? " first" : "")
                  }
                >



                  <Col key={key} span={24}>
                    <div className={"brand_title"}>{key}</div>
                  </Col>

                  {values.map((artilce, index) => (
                    <Col
                      className={
                        values.length === index + 1 ? "" : "not-last-article"
                      }
                      key={`article-col-${artilce.id}-${artilce.code}`}
                      span={24}
                    >
                      <ArticleCard
                        mode={!compact ? "Normal" : "Normal"}
                        account={account}
                        article={artilce}
                        history={history}
                        key={`article-${artilce.id}-${artilce.code}`}
                      />
                    </Col>
                  ))}
                </Row>
              </>
            )
          )}
        </>
      );
    }); */
  },
  (prev, next) => dequal(prev, next)
);
