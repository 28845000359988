import { FieldTimeOutlined, UserOutlined } from "@ant-design/icons";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { Input, List, Spin } from "antd";
import React, { useContext, useState } from "react";
import ReactGA from "react-ga4";
import Highlighter from "react-highlight-words";
import { TabContext } from "../../../../Context/Tab";
import { ADD_RECHERCHE } from "../../../../shared/graphql/mutations/recherche";
import { QUERY_LAST_RECHERCHE_AND_COUNT } from "../../../../shared/graphql/queries/recherche";
import { SCREENS, TABS_ACTIONS } from "../../../../utils/constants";
import {
  datetimeFormatter,
  numberFormatter,
} from "../../../../utils/formatter-functions";
import { format } from "../../../../utils/helpers";
const { Search } = Input;

export const HistoriqueReference = (props) => {
  const [searchedReference, setSearchedReference] = useState("");
  const { tabs, dispatch } = useContext(TabContext);
  const { type } = props;
  const {
    data,
    loading: isLoading,
    error,
    refetch,
  } = useQuery(QUERY_LAST_RECHERCHE_AND_COUNT, {
    fetchPolicy: "network-only",
    variables: {
      lastRechercheInput: {
        motCle: format(searchedReference),
        type: type
          .split(/(?=[A-Z])/)
          .join("_")
          .toLowerCase(),
        orderBy: "lastVisitedDate",
      },
    },
  });

  return (
    <div>
      <div style={{ padding: "0px 15px" }}>
        <Search
          size="medium"
          placeholder={
            type === "reference"
              ? "Tapez une référence article"
              : type === "vin"
              ? "Tapez un VIN"
              : "Tapez un code moteur"
          }
          onChange={(event) => {
            setSearchedReference(event.target.value);
          }}
          onSearch={(e) => {
            if (e.length >= 3)
              return dispatch({
                type: TABS_ACTIONS.HISTORY_PUSH,
                payload: {
                  screen: SCREENS.ARTICLES,
                  props: {
                    search: {
                      reference: e,
                      type: "direct",
                    },
                  },
                },
              });
          }}
          onPressEnter={(e) => {
            if (e.target.value.length >= 3)
              return dispatch({
                type: TABS_ACTIONS.HISTORY_PUSH,
                payload: {
                  screen: SCREENS.ARTICLES,
                  props: {
                    search: {
                      reference: e.target.value,
                      type: "direct",
                    },
                  },
                },
              });
          }}
        />
      </div>
      <Spin spinning={isLoading}>
        <div
          style={{
            padding: "5px 0px",
            margin: "15px 0px 0px 0px",
            overflow: "auto",
            height: "calc((100vh / 1) - 450px)",
          }}
        >
          {data && (
            <List
              size="medium"
              dataSource={data.lastRechercheAndCount}
              renderItem={(item, index) => (
                <div
                  className="div-vertical table-list-item"
                  style={{
                    padding: "8px 25px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    onClick={() => {
                      return dispatch({
                        type: TABS_ACTIONS.HISTORY_PUSH,
                        payload: {
                          screen: SCREENS.ARTICLES,
                          props: {
                            search: {
                              reference: item[type],
                              type: "direct",
                            },
                          },
                        },
                      });
                    }}
                  >
                    <Highlighter
                      className="not-hightlighted-text"
                      highlightTag={({ children, highlightIndex }) => (
                        <strong
                          className="highlighted-text"
                          style={{ color: "red" }}
                        >
                          {children}
                        </strong>
                      )}
                      searchWords={[format(searchedReference)]}
                      autoEscape={true}
                      textToHighlight={item[type]}
                    />

                    <span className="div-horizontal center">
                      <label
                        style={{
                          fontWeight: "bold",
                          margin: "0",
                          marginRight: "5px",
                          textAlign: "right",
                        }}
                      >
                        {"(" + numberFormatter(item.count) + ")"}
                      </label>
                      <UserOutlined />
                    </span>
                  </div>

                  <div
                    className="div-horizontal center"
                    style={{ width: "100%" }}
                  >
                    <FieldTimeOutlined style={{ color: "rgba(0,0,0,1)" }} />
                    <label
                      style={{
                        margin: 0,
                        marginLeft: "5px",
                        color: "rgba(0,0,0,1)",
                        flexGrow: "1",
                        fontWeight: "bold",
                        fontSize: "11px",
                        textAlign: "left",
                      }}
                    >
                      {datetimeFormatter(item.lastVisitedDate)}
                    </label>
                  </div>
                </div>
              )}
            />
          )}
        </div>
      </Spin>
    </div>
  );
};

export const HistoriqueVin = () => {
  const [searchedReference, setSearchedReference] = useState("");
  const { tabs, dispatch } = useContext(TabContext);

  const {
    data,
    loading: isLoading,
    error,
    refetch,
  } = useQuery(QUERY_LAST_RECHERCHE_AND_COUNT, {
    fetchPolicy: "network-only",
    variables: {
      lastRechercheInput: {
        motCle: format(searchedReference),
        type: "vin",
        orderBy: "lastVisitedDate",
      },
    },
  });

  const [addRecherche, { dataAddRecherche, loading, errorAddRecherche }] =
    useMutation(ADD_RECHERCHE);

  return (
    <div>
      <div style={{ padding: "0px 15px" }}>
        <Input
          size="medium"
          placeholder="Tapez un code VIN..."
          onChange={(event) => {
            setSearchedReference(event.target.value);
          }}
        />
      </div>
      <Spin spinning={isLoading}>
        <div
          style={{
            padding: "5px 0px",
            margin: "15px 0px 0px 0px",
            overflow: "auto",
            height: "calc((100vh / 1) - 450px)",
          }}
        >
          {data && (
            <List
              size="medium"
              dataSource={data.lastRechercheAndCount}
              renderItem={(item, index) => (
                <div
                  className="div-vertical table-list-item"
                  style={{
                    padding: "8px 25px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    onClick={() => {
                      ReactGA.event({
                        category: "recherche",
                        action: "vin",
                        label: `${
                          item.vin.toUpperCase() +
                          " (" +
                          item.motorisation.modele.marque.designation +
                          " ->" +
                          item.motorisation.modele.designation +
                          " ->" +
                          item.motorisation.designation +
                          ")"
                        }`.toUpperCase(), // optional
                        value: 99, // optional, must be a number
                        //  nonInteraction: true, // optional, true/false
                        //  transport: "xhr", // optional, beacon/xhr/image
                      });

                      addRecherche({
                        variables: {
                          rechercheInput: {
                            vin: item.vin,
                            type: "vin",

                            motorisation: {
                              id: item.motorisation.id,
                              designation: item.motorisation.designation,

                              modele: {
                                id: item.motorisation.modele.id,
                                designation:
                                  item.motorisation.modele.designation,
                                marque: {
                                  id: item.motorisation.modele.marque.id,
                                  designation:
                                    item.motorisation.modele.marque.designation,
                                },
                              },
                            },
                          },
                        },
                      });
                      console.log("dispatch 9 *******");

                      dispatch({
                        type: TABS_ACTIONS.HISTORY_PUSH,
                        payload: {
                          screen: SCREENS.ASSEMBLYGROUPS,
                          props: {
                            search: {
                              linkingTarget: item.motorisation.id,
                              manufacturer: item.motorisation.modele.marque.id,
                              manufacturerBean: {
                                id: item.motorisation.modele.marque.id,
                                name: item.motorisation.modele.marque
                                  .designation,
                              },
                              model: item.motorisation.modele.id,
                              modelBean: {
                                id: item.motorisation.modele.id,
                                name: item.motorisation.modele.designation,
                              },
                              motorizationBean: {
                                id: item.motorisation.id,
                                name: item.motorisation.designation,
                              },
                            },
                          },
                        },
                      });
                    }}
                  >
                    <div className="div-vertical">
                      <Highlighter
                        className="not-hightlighted-text"
                        highlightTag={({ children, highlightIndex }) => (
                          <strong
                            className="highlighted-text"
                            style={{ color: "red" }}
                          >
                            {children}
                          </strong>
                        )}
                        searchWords={[format(searchedReference)]}
                        autoEscape={true}
                        textToHighlight={item.vin.toUpperCase()}
                      />

                      <Highlighter
                        className="not-hightlighted-text"
                        highlightTag={({ children, highlightIndex }) => (
                          <strong
                            className="highlighted-text"
                            style={{ color: "red" }}
                          >
                            {children}
                          </strong>
                        )}
                        searchWords={[format(searchedReference)]}
                        autoEscape={true}
                        textToHighlight={
                          "(" +
                          item.motorisation.modele.marque.designation +
                          " -> " +
                          item.motorisation.modele.designation +
                          " -> " +
                          item.motorisation.designation +
                          ")"
                        }
                      />
                    </div>

                    <span className="div-horizontal center">
                      <label
                        style={{
                          fontWeight: "bold",
                          margin: "0",
                          marginRight: "5px",
                          textAlign: "right",
                        }}
                      >
                        {"(" + numberFormatter(item.count) + ")"}
                      </label>
                      <UserOutlined />
                    </span>
                  </div>

                  <div
                    className="div-horizontal center"
                    style={{ width: "100%" }}
                  >
                    <FieldTimeOutlined style={{ color: "rgba(0,0,0,1)" }} />
                    <label
                      style={{
                        margin: 0,
                        marginLeft: "5px",
                        color: "rgba(0,0,0,1)",
                        flexGrow: "1",
                        fontWeight: "bold",
                        fontSize: "11px",
                        textAlign: "left",
                      }}
                    >
                      {datetimeFormatter(item.lastVisitedDate)}
                    </label>
                  </div>
                </div>
              )}
            />
          )}
        </div>
      </Spin>
    </div>
  );
};

export const HistoriqueVehicule = () => {
  const [searchedReference, setSearchedReference] = useState("");
  const { tabs, dispatch } = useContext(TabContext);

  const {
    data,
    loading: isLoading,
    error,
    refetch,
  } = useQuery(QUERY_LAST_RECHERCHE_AND_COUNT, {
    fetchPolicy: "network-only",
    variables: {
      lastRechercheInput: {
        motCle: format(searchedReference),
        type: "motorisation_famille",
        orderBy: "lastVisitedDate",
      },
    },
  });

  return (
    <div>
      <div style={{ padding: "0px 15px" }}>
        <Input
          size="medium"
          placeholder="Tapez une famille, une marque, un modèle..."
          onChange={(event) => {
            setSearchedReference(event.target.value);
          }}
        />
      </div>
      <Spin spinning={isLoading}>
        <div
          style={{
            padding: "5px 0px",
            margin: "15px 0px 0px 0px",
            overflow: "auto",
            height: "calc((100vh / 1) - 450px)",
          }}
        >
          {data && (
            <List
              size="medium"
              dataSource={data.lastRechercheAndCount}
              renderItem={(item, index) => (
                <div
                  className="div-vertical table-list-item"
                  style={{
                    padding: "8px 25px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    onClick={() => {
                      return dispatch({
                        type: TABS_ACTIONS.HISTORY_PUSH,
                        payload: {
                          screen: SCREENS.ARTICLES,
                          props: {
                            search: {
                              assemblyGroup: {
                                id: item.famille.id,
                                name: item.famille.designation,
                              },
                              car: { id: item.motorisation.id },
                              manufacturerBean: {
                                id: item.motorisation.modele.marque.id,
                                name: item.motorisation.modele.marque
                                  .designation,
                              },
                              modelBean: {
                                id: item.motorisation.modele.id,
                                name: item.motorisation.modele.designation,
                              },
                              motorizationBean: {
                                id: item.motorisation.id,
                                name: item.motorisation.designation,
                              },
                              name: item.famille.designation,
                            },
                          },
                        },
                      });
                    }}
                  >
                    <div className="div-vertical">
                      <Highlighter
                        className="not-hightlighted-text"
                        highlightTag={({ children, highlightIndex }) => (
                          <strong
                            className="highlighted-text"
                            style={{ color: "red" }}
                          >
                            {children}
                          </strong>
                        )}
                        searchWords={[format(searchedReference)]}
                        autoEscape={true}
                        textToHighlight={item.famille.designation}
                      />

                      <Highlighter
                        className="not-hightlighted-text"
                        highlightTag={({ children, highlightIndex }) => (
                          <strong
                            className="highlighted-text"
                            style={{ color: "red" }}
                          >
                            {children}
                          </strong>
                        )}
                        searchWords={[format(searchedReference)]}
                        autoEscape={true}
                        textToHighlight={
                          "(" +
                          item.motorisation.modele.marque.designation +
                          " -> " +
                          item.motorisation.modele.designation +
                          " -> " +
                          item.motorisation.designation +
                          ")"
                        }
                      />
                    </div>

                    <span className="div-horizontal center">
                      <label
                        style={{
                          fontWeight: "bold",
                          margin: "0",
                          marginRight: "5px",
                          textAlign: "right",
                        }}
                      >
                        {"(" + numberFormatter(item.count) + ")"}
                      </label>
                      <UserOutlined />
                    </span>
                  </div>

                  <div
                    className="div-horizontal center"
                    style={{ width: "100%" }}
                  >
                    <FieldTimeOutlined style={{ color: "rgba(0,0,0,1)" }} />
                    <label
                      style={{
                        margin: 0,
                        marginLeft: "5px",
                        color: "rgba(0,0,0,1)",
                        flexGrow: "1",
                        fontWeight: "bold",
                        fontSize: "11px",
                        textAlign: "left",
                      }}
                    >
                      {datetimeFormatter(item.lastVisitedDate)}
                    </label>
                  </div>
                </div>
              )}
            />
          )}
        </div>
      </Spin>
    </div>
  );
};

export const HistoriqueEquipementier = () => {
  const [searchedReference, setSearchedReference] = useState("");
  const { tabs, dispatch } = useContext(TabContext);

  const {
    data,
    loading: isLoading,
    error,
    refetch,
  } = useQuery(QUERY_LAST_RECHERCHE_AND_COUNT, {
    fetchPolicy: "network-only",
    variables: {
      lastRechercheInput: {
        motCle: format(searchedReference),
        type: "equipementier_famille",
        orderBy: "lastVisitedDate",
      },
    },
  });

  return (
    <div>
      <div style={{ padding: "0px 15px" }}>
        <Input
          size="medium"
          placeholder="Tapez une famille, un équipementier..."
          onChange={(event) => {
            setSearchedReference(event.target.value);
          }}
        />
      </div>
      <Spin spinning={isLoading}>
        <div
          style={{
            padding: "5px 0px",
            margin: "15px 0px 0px 0px",
            overflow: "auto",
            height: "calc((100vh / 1) - 450px)",
          }}
        >
          {data && (
            <List
              size="medium"
              dataSource={data.lastRechercheAndCount}
              renderItem={(item, index) => (
                <div
                  className="div-vertical table-list-item"
                  style={{
                    padding: "8px 25px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    onClick={() => {
                      console.log("click 100 supplierAssembly");

                      return dispatch({
                        type: TABS_ACTIONS.HISTORY_PUSH,
                        payload: {
                          screen: SCREENS.ARTICLES,
                          props: {
                            search: {
                              type: "supplierAssembly",

                              brand: { id: item.equipementier.id },
                              brandBean: {
                                id: item.equipementier.id,
                                name: item.equipementier.designation,
                              },
                              assemblyGroupBean: {
                                id: item.famille.id,
                                name: item.famille.designation,
                              },
                              assemblyGroup: { id: item.famille.id },
                            },
                          },
                        },
                      });
                    }}
                  >
                    <Highlighter
                      className="not-hightlighted-text"
                      highlightTag={({ children, highlightIndex }) => (
                        <strong
                          className="highlighted-text"
                          style={{ color: "red" }}
                        >
                          {children}
                        </strong>
                      )}
                      searchWords={[format(searchedReference)]}
                      autoEscape={true}
                      textToHighlight={
                        item.famille.designation +
                        " (" +
                        item.equipementier.designation +
                        ")"
                      }
                    />

                    <span className="div-horizontal center">
                      <label
                        style={{
                          fontWeight: "bold",
                          margin: "0",
                          marginRight: "5px",
                          textAlign: "right",
                        }}
                      >
                        {"(" + numberFormatter(item.count) + ")"}
                      </label>
                      <UserOutlined />
                    </span>
                  </div>

                  <div
                    className="div-horizontal center"
                    style={{ width: "100%" }}
                  >
                    <FieldTimeOutlined style={{ color: "rgba(0,0,0,1)" }} />
                    <label
                      style={{
                        margin: 0,
                        marginLeft: "5px",
                        color: "rgba(0,0,0,1)",
                        flexGrow: "1",
                        fontWeight: "bold",
                        fontSize: "11px",
                        textAlign: "left",
                      }}
                    >
                      {datetimeFormatter(item.lastVisitedDate)}
                    </label>
                  </div>
                </div>
              )}
            />
          )}
        </div>
      </Spin>
    </div>
  );
};
