import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { Col, Row, Spin, Table } from "antd";
import { isEmpty, isNil } from "lodash";
import get from "lodash/get";
import groupBy from "lodash/groupBy";
import uniqBy from "lodash/uniqBy";
import React, { useContext, useEffect, useState } from "react";
import { TabContext } from "../../../../../../Context/Tab";
import { ManufacturersQueries } from "../../../../../../shared/graphql/queries";
import { images, links } from "../../../../../../utils/Logos/Cars";
import {
  DEFAULT_VEHICLES,
  SCREENS,
  TABS_ACTIONS,
} from "../../../../../../utils/constants";
const { ARTICLE, SEARCH } = ManufacturersQueries;
export default React.memo(({ article, ...props }) => {
  const [skip, setSkip] = useState(0);
  const [manufacturerId, setManufacturerId] = useState("");
  const [modelId, setModelId] = useState("");
  const [cumulData, setCumulData] = useState([]);
  const { dispatch } = useContext(TabContext);
  const variables = {
    where: {
      by: "vehicle",
      type: "vehicle",
      criteria: {
        article: { code: article.code, id: article.referenceArticle },
      },
      skip: skip,
      take: DEFAULT_VEHICLES,
    },
  };

  const { loading, data: articles } = useQuery(ARTICLE, {
    variables: { isIn: [{ id: article.referenceArticle }] },
  });
  const [getSearch, { loading: loadingSearch, data }] = useLazyQuery(SEARCH);
  useEffect(() => {
    setModelId("");
  }, [manufacturerId]);

  useEffect(() => {
    // console.log("getSearch *******************************");
    getSearch({
      variables,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip]);
  useEffect(() => {
    if (data) {
      setCumulData(
        uniqBy([...cumulData, ...get(data, "search.data", [])], "id")
      );
      if (!isEmpty(get(data, "search.data", []))) {
        setSkip(skip + 100);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (loading) return null;
  const manufacturers = groupBy(cumulData, "manufacturer.id");
  return (
    <div style={{ paddingBottom: "25px" }}>
      <h3 className="tab__detail--article--title">Marques</h3>
      <div className="div-vertical" style={{ overflow: "auto" }}>
        <div style={{ paddingTop: "10px" }} className="car-list">
          {Object.keys(manufacturers).map((key) => (
            <Col key={`${key}`} xs={12} sm={6} md={3} lg={3} xl={3}>
              <div
                className={
                  "card cars_item " + (manufacturerId === key && "clicked")
                }
                onClick={() => {
                  setManufacturerId(key);
                }}
              >
                <div className="imgbrand">
                  <img
                    className="text-center"
                    src={
                      !isNil(
                        images[
                          get(manufacturers[key][0], "manufacturer.name", "")
                        ]
                      )
                        ? images[
                            get(manufacturers[key][0], "manufacturer.name", "")
                          ]
                        : !isNil(
                            links[
                              get(
                                manufacturers[key][0],
                                "manufacturer.name",
                                ""
                              )
                            ]
                          )
                        ? links[
                            get(manufacturers[key][0], "manufacturer.name", "")
                          ]
                        : links.default
                    }
                    alt=""
                  />
                </div>
                <p style={{ fontSize: 8 }}>
                  {get(manufacturers[key][0], "manufacturer.name", "")}
                </p>
              </div>
            </Col>
          ))}
        </div>
      </div>
      {loadingSearch && <Spin />}

      <h3 className="tab__detail--article--title">
        {manufacturerId ? "Modèles" : ""}
      </h3>
      <Row
        className="div-horizontal"
        style={{
          justifyContent: "stretch",
          flexWrap: "wrap",
          alignItems: "stretch",
        }}
        gutter={16}
      >
        {(uniqBy(manufacturers[manufacturerId], "model.id") || []).map(
          (car) => (
            <Col
              span={4}
              style={{ marginTop: "5px", marginBottom: "5px" }}
              onClick={() => {
                setModelId(car.model.id);
              }}
            >
              <div
                className={`div-vertical center center-justified tab__detail--article--brand ${
                  modelId === car.model.id ? "active" : ""
                }`}
              >
                <object
                  style={{
                    marginBottom: 5,

                    height: 30,
                  }}
                  key={car.model.id}
                  data={`https://media.carparts-cat.com/webdata2/kmodthumb/${car.model.id}.png`}
                  type="image/png"
                >
                  <img
                    style={{ height: 30 }}
                    src="https://www.freeiconspng.com/uploads/no-image-icon-13.png"
                    alt="Modèle"
                  />
                </object>

                <label>{car.model.name}</label>
              </div>
            </Col>
          )
        )}
      </Row>
      {modelId && (
        <>
          <h3 className="tab__detail--article--title">Motorisations</h3>
          <Table
            bordered
            size="medium"
            pagination={false}
            onRow={(record) => {
              return {
                onClick: () => {
                  dispatch({
                    type: TABS_ACTIONS.HISTORY_PUSH,
                    payload: {
                      screen: SCREENS.ARTICLES,
                      props: {
                        search: {
                          car: { id: record.car.id },
                          motorizationBean: record.car,
                          modelBean: record.car.model,
                          manufacturerBean: record.car.manufacturer,
                          assemblyGroup: {
                            id: get(
                              articles,
                              "article.data[0].assemblyGroups[0].id"
                            ),
                            name: get(
                              articles,
                              "article.data[0].assemblyGroups[0].name"
                            ),
                          },
                          name: get(articles, "article.data[0].name"),
                        },
                      },
                    },
                  });
                },
              };
            }}
            dataSource={(manufacturers[manufacturerId] || [])
              .filter((car) => car.model.id === modelId)
              .map((car) => {
                // console.log("car0", car);
                return {
                  key: "name",
                  name: car.name,
                  Cylinder: car.cylinders,
                  yearOfConstructionFrom: get(
                    car,
                    "yearOfConstructionFrom",
                    ""
                  ),
                  HorsePower:
                    car.powerHorsePowerFrom + "-" + car.powerHorsePowerTo,

                  powerKw: car.powerKwFrom + "-" + car.powerKwTo,
                  ccmTech: car.capacityCC,
                  cylinderCapacityCcm: car.capacityCC,
                  motorType: car.motorType,
                  FuelType: car.FuelType,
                  codeMoteur: get(car, "motors[0]"),
                  car: car,
                };
              })}
            columns={[
              {
                title: "Nom",
                dataIndex: "name",
                key: "name",
              },
              {
                title: "Cylinder",
                dataIndex: "Cylinder",
                key: "Cylinder",
              },
              {
                title: "Année de construction",
                dataIndex: "yearOfConstructionFrom",
                key: "yearOfConstructionFrom",
              },
              {
                title: "HorsePower",
                dataIndex: "HorsePower",
                key: "HorsePower",
              },
              {
                title: "powerKw",
                dataIndex: "powerKw",
                key: "powerKw",
              },
              {
                title: "ccmTech",
                dataIndex: "ccmTech",
                key: "ccmTech",
              },
              {
                title: "cylinderCapacityCcm",
                dataIndex: "namecylinderCapacityCcm",
                key: "cylinderCapacityCcm",
              },
              {
                title: "Type de moteur",
                dataIndex: "motorType",
                key: "motorType",
              },
              {
                title: "FuelType",
                dataIndex: "FuelType",
                key: "FuelType",
              },
              {
                title: "Code moteur",
                dataIndex: "codeMoteur",
                key: "codeMoteur",
              },
            ]}
          />
        </>
      )}
    </div>
  );
});
