import { useLazyQuery } from "@apollo/react-hooks";
import { AutoComplete, Spin } from "antd";
import React, { useContext, useEffect, useState } from "react";
import Highlighter from "react-highlight-words";
import { TabContext } from "../../../../../Context/Tab";
import { CARS } from "../../../../../shared/graphql/queries/manufacturers";
import { SCREENS, TABS_ACTIONS } from "../../../../../utils/constants";
import { format } from "../../../../../utils/helpers";

const { Option, OptGroup } = AutoComplete;
export default ({ active }) => {
  const { dispatch } = useContext(TabContext);

  //CV24 AUDI A5

  const [codeMoteur, setCodeMoteur] = useState("");
  useEffect(() => {
    if (!active) {
      setCodeMoteur("");
    }
  }, [active]);

  const [getVehiculesByCodeMoteur, { data, loading }] = useLazyQuery(CARS);

  // console.log("loading", loading);

  const options = data
    ? [
        <OptGroup key={"Suggestions Code moteur"}>
          {loading && (
            <Option key={"loading"} value={"loading"}>
              <div
                className="div-vertical center center-justified"
                style={{ height: 70 }}
              >
                <Spin />
              </div>
            </Option>
          )}
          {data?.cars?.data.map(({ id, name, manufacturer, model }) => (
            <Option key={id} value={id}>
              <div
                className="div-vertical"
                onClick={() => {
                  console.log("dispatch 8 *******");

                  dispatch({
                    type: TABS_ACTIONS.HISTORY_PUSH,
                    payload: {
                      screen: SCREENS.ASSEMBLYGROUPS,
                      props: {
                        search: {
                          linkingTarget: id,
                          manufacturer: manufacturer.id,
                          manufacturerBean: manufacturer,
                          model: model.id,
                          modelBean: model,
                          motorizationBean: { id, name, model, manufacturer },
                        },
                      },
                    },
                  });
                }}
              >
                <div className="div-horizontal center">
                  <div
                    className="div-horizontal center center-justified"
                    style={{ width: 80 }}
                  >
                    <object
                      style={{
                        marginRight: 10,

                        height: 20,
                      }}
                      key={model.id}
                      data={`https://media.carparts-cat.com/webdata2/kmodthumb/${model.id}.png`}
                      type="image/png"
                    >
                      <img
                        style={{ height: 20 }}
                        src="https://www.freeiconspng.com/uploads/no-image-icon-13.png"
                        alt="Modèle"
                      />
                    </object>
                  </div>
                  <Highlighter
                    highlightClassName="YourHighlightClass"
                    highlightTag={({ children, highlightIndex }) => (
                      <strong
                        className="highlighted-text"
                        style={{ color: "red" }}
                      >
                        {children}
                      </strong>
                    )}
                    searchWords={[format(codeMoteur)]}
                    autoEscape={true}
                    textToHighlight={
                      manufacturer.name + " " + model.name + " " + name
                    }
                  />
                </div>
              </div>
            </Option>
          ))}
        </OptGroup>,
      ]
    : [
        <OptGroup key={"Suggestions Code moteur"}>
          {loading && (
            <Option key={"loading"} value={"loading"}>
              <div
                className="div-vertical center center-justified"
                style={{ height: 70 }}
              >
                <Spin />
              </div>
            </Option>
          )}
        </OptGroup>,
      ];

  return (
    <AutoComplete
      placement={"bottomCenter"}
      dropdownClassName="recherche-autocomplete-dropdown"
      dropdownMatchSelectWidth={false}
      size="medium"
      value={codeMoteur}
      style={{ width: "100%" }}
      dataSource={options}
      placeholder="Cherchez un Code moteur"
      optionLabelProp="value"
      onChange={(value) => {
        // console.log("codeMoteur", value);

        setCodeMoteur(value);
        if (value.length >= 3)
          getVehiculesByCodeMoteur({
            variables: { where: { motor: { name: value } } },
          });
      }}
    />
  );
};
