import { useLazyQuery } from "@apollo/react-hooks";
import { Button, Input, Row, Select, Table, Tag, Tooltip } from "antd";
import { get, isEmpty, uniqBy } from "lodash";
import isNil from "lodash/isNil";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { TabContext } from "../../../../../Context/Tab";
import Breadcrumb from "../../../../../shared/components/Breadcrumb";
import { ManufacturersQueries } from "../../../../../shared/graphql/queries";
import { SCREENS, TABS_ACTIONS } from "../../../../../utils/constants";

const { Option } = Select;
const { VEHICLES } = ManufacturersQueries;

const columns = ({ filter, setFilter }) => [
  {
    title: (
      <span style={{ textAlign: "center", color: "black" }}>
        Type de véhicule
      </span>
    ),
    dataIndex: "name",
    key: "name",
  },
  {
    title: <span style={{ textAlign: "center", color: "black" }}>Année</span>,
    key: "dates",
    render: (text, record) => (
      <span key={record.id}>
        {moment(record.yearOfConstructionFrom, "YYYYMM").format("MM.YY")}
        {!isNil(record.yearOfConstructionTo) &&
          ` - ${moment(record.yearOfConstructionTo, "YYYYMM").format("MM.YY")}`}
      </span>
    ),
  },
  {
    title: <span style={{ textAlign: "center", color: "black" }}>KW</span>,
    key: "kw",
    render: (text, record) => <span key={record.id}>{record.powerKwTo}</span>,
  },
  {
    title: <span style={{ textAlign: "center", color: "black" }}>CH</span>,
    key: "ch",
    render: (text, record) => (
      <span key={record.id}>{record.powerHorsePowerTo}</span>
    ),
  },
  {
    title: (
      <span style={{ textAlign: "center", color: "black" }}>Cylindrée ccm</span>
    ),
    dataIndex: "cylinderCapacityCcm",
    key: "cylinderCapacityCcm",
  },
  {
    title: (
      <span style={{ textAlign: "center", color: "black" }}>Cylindre</span>
    ),
    dataIndex: "cylinder",
    key: "cylinder",
  },
  {
    width: 350,
    title: (
      <span style={{ textAlign: "center", color: "black" }}>Codes moteur</span>
    ),
    key: "motors",
    render: (text, record) => (
      <Row
        className="div-horizontal"
        style={{
          justifyContent: "stretch",
          flexWrap: "wrap",
          alignItems: "stretch",
          margin: "0px 5px",
        }}
        gutter={16}
      >
        {record.motors.map((motor) => (
          <Tag style={{ marginTop: "2px", marginBottom: "2px" }} key={motor}>
            {motor}
          </Tag>
        ))}
      </Row>
    ),
  },
  {
    title: (
      <Tooltip placement="topLeft" title="Type de carburant" arrowPointAtCenter>
        <Input
          placeholder="Type de carburant"
          allowClear
          value={filter.fuelType}
          onChange={(_) => setFilter({ ...filter, fuelType: _.target.value })}
        />
      </Tooltip>
    ),
    dataIndex: "fuelType",
    key: "fuelType",
  },
];

const VehiclesView = ({
  years,
  models,
  manufacturer,
  filter,
  setFilter,
  search,
  model,
  modelBean,
  manufacturerBean,
  car,
}) => {
  const { dispatch } = useContext(TabContext);
  const [vehicles, setVehicles] = useState(models);
  const [take, setTake] = useState(0);

  const variables = {
    where: {
      manufacturer: {
        id: get(manufacturer, "id"),
      },
      model: {
        id: model,
      },
    },
    take: take,
  };
  const [getVehicles, { loading, data }] = useLazyQuery(VEHICLES);
  const vehiclesData = get(data && data, "vehicles.data", []);

  useEffect(() => {
    getVehicles({
      variables,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [take]);

  useEffect(() => {
    if (data) {
      setVehicles(uniqBy([...vehicles, ...vehiclesData], "id"));
    }
    if (!isEmpty(vehiclesData)) {
      setTake(take + 25);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const sortedVehicles = vehicles.sort((a, b) =>
    a.fuelType > b.fuelType ? 1 : -1
  );

  return (
    <div className="home_section_bg">
      <div className="section full-height">
        <div className="div-vertical growed">
          <div className="container">
            <div className="titre-div-horizontal" style={{ padding: 0 }}>
              <Breadcrumb location={{ search }} />
              <Button
                size={"medium"}
                type="primary"
                onClick={() => setFilter({ ...filter, year: null })}
              >
                Réinitialiser
              </Button>
              <Select
                size={"medium"}
                showSearch
                placeholder="Année"
                style={{
                  width: "160px",
                  textAlign: "center",
                  marginLeft: "10px",
                }}
              ></Select>
            </div>

            <div
              className="div-horizontal wrapped"
              style={{ marginBottom: 15, marginTop: 15 }}
            >
              {years.map((year) => (
                <Button
                  key={year}
                  shape="round"
                  className="btn_filter_year"
                  onClick={() => setFilter({ ...filter, year })}
                >
                  {year}
                </Button>
              ))}
            </div>

            <Table
              bordered
              loading={loading}
              //  scroll={{ x: true }}
              scroll={{ y: "calc((100vh / 1) - 395px)" }}
              rowKey={(record) => record.id}
              columns={columns({
                filter,
                setFilter,
              })}
              dataSource={sortedVehicles}
              size="medium"
              className="table__custom"
              pagination={false}
              onRow={(record) => {
                return {
                  onClick: () => {
                    console.log("dispatch 5 *******");

                    return dispatch({
                      type: TABS_ACTIONS.HISTORY_PUSH,
                      payload: {
                        screen: SCREENS.ASSEMBLYGROUPS,
                        props: {
                          search: {
                            linkingTarget: record.id,
                            ...(car
                              ? {}
                              : {
                                  manufacturer: manufacturer.id,
                                  model: model,
                                  manufacturerBean: manufacturerBean,
                                  modelBean: modelBean,
                                  motorizationBean: record,
                                }),
                          },
                        },
                      },
                    });
                  },
                };
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default VehiclesView;
