import {
  FacebookFilled,
  InstagramFilled,
  LinkedinFilled,
  YoutubeFilled,
} from "@ant-design/icons";
import { Badge } from "antd";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { TabContext } from "../../../../../Context/Tab";
import { SCREENS, TABS_ACTIONS } from "../../../../../utils/constants";
import { StoreContext } from "../../../../store";
import "../styles.scss";

export default () => {
  const { dispatch } = useContext(TabContext);
  const { user } = useContext(StoreContext);

  return (
    <div className="footer-clean">
      <div className="barniere-voile-noir-light"></div>
      <div
        className="container"
        style={{ paddingTop: "50px", paddingBottom: "10px" }}
      >
        <div className="row justify-content-center">
          <div className="col-sm-4 col-md-3 item">
            <h3>POLITIQUES & INFOS</h3>
            <ul>
              <li>
                <Link to={"/terms-conditions"}>Termes et conditions</Link>
              </li>
              <li>
                <Link to={"/terms-conditions"}>Mode de livraison</Link>
              </li>
              <li>
                <Link to={"/terms-conditions"}>Politique de retour</Link>
              </li>

              <li>
                <Link to={"/terms-conditions"}>Modes de paiement</Link>
              </li>
            </ul>
          </div>
          <div className="col-sm-4 col-md-3 item">
            <h3>LIENS RAPIDES</h3>
            <ul>
              <li>
                <Link to={"/catalog"}>Catalogue web</Link>
              </li>
              <li>
                <Link to={"/catalog"}>Nos produits</Link>
              </li>
              <li>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    return dispatch({
                      type: TABS_ACTIONS.HISTORY_PUSH,
                      payload: {
                        screen: SCREENS.CATALOG,
                        props: {
                          tab: "vehicles",
                        },
                      },
                    });
                  }}
                >
                  Nos marques
                </span>
              </li>

              <li>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    return dispatch({
                      type: TABS_ACTIONS.HISTORY_PUSH,
                      payload: {
                        screen: SCREENS.CATALOG,
                        props: {
                          tab: "manufacturers",
                        },
                      },
                    });
                  }}
                >
                  Nos partenaires
                </span>
              </li>
              <li>
                <Link to={"/"}>FAQ</Link>
              </li>
            </ul>
          </div>
          <div className="col-sm-4 col-md-3 item">
            <h3>MON COMPTE</h3>
            <ul>
              <li>
                <Link to={"/profile/me"}>Mon profil</Link>
              </li>

              <li>
                <Link to={"/recherches"}>
                  <div className="div-horizontal" style={{ width: "100%" }}>
                    <span style={{ marginRight: 10 }}>Mes recherches</span>
                    <Badge count={"NEW"} />
                  </div>
                </Link>
              </li>

              <li>
                <Link to={"/achats"}>
                  <div className="div-horizontal" style={{ width: "100%" }}>
                    <span style={{ marginRight: 10 }}>
                      {"Mes " +
                        (user.role.name === "admin" ||
                        user.role.name === "commercial" ||
                        user.role.name === "commercial régional"
                          ? "ventes"
                          : "achats")}
                    </span>
                    <Badge count={"NEW"} />
                  </div>
                </Link>
              </li>

              <li>
                <Link to={"/entries"}>Mes entrées</Link>
              </li>

              <li>
                <Link to={"/retours"}>
                  <div className="div-horizontal" style={{ width: "100%" }}>
                    <span style={{ marginRight: 10 }}>Mes retours</span>
                    <Badge count={"NEW"} />
                  </div>
                </Link>
              </li>

              <li>
                <Link to={"/invoices"}>Mes factures</Link>
              </li>
              <li>
                <Link to={"/assets"}>Mes avoirs</Link>
              </li>

              <li>
                <Link to={"/orders"}>Mes commandes</Link>
              </li>

              {/*   <li>
                <Link to={"/quotes"}>Mes devis</Link>
              </li> */}

              <li>
                <Link to={"/wish-list-items"}>Liste des favoris</Link>
              </li>
            </ul>
          </div>
        </div>

        <div className=" center justify-content-center  item social">
          <Link
            to={{
              pathname: "https://facebook.com",
            }}
            target="_blank"
          >
            <FacebookFilled />
          </Link>
          <Link
            to={{
              pathname: "https://instagram.com",
            }}
            target="_blank"
          >
            <InstagramFilled />
          </Link>
          <Link
            to={{
              pathname: "https://youtube.com",
            }}
            target="_blank"
          >
            <YoutubeFilled />
          </Link>
          <Link
            to={{
              pathname: "https://linkedin.com",
            }}
            target="_blank"
          >
            <LinkedinFilled />
          </Link>
          <p className="copyright">
            {"AUTOPLUS © " + new Date().getFullYear()}
          </p>
        </div>
      </div>
    </div>

    /*   <div className="footer_top">
      <div className="container">
        <div className="div-horizontal wrapped">
          <div className="col-lg-3 col-md-5 col-sm-7">
            <div className="widgets_container contact_us">
              <h3>Nous Contacter</h3>
              <div className="aff_content">
                <ul>
                  <li>
                    <p>
                      <Link to={"tel:+212522328117">
                        <FontAwesomeIcon icon={faPhone}></FontAwesomeIcon>
                        <span> (+212) 0522-328117</span>
                      </Link>
                    </p>
                    <p>
                      <Link to={"tel:+212522325418">
                        <FontAwesomeIcon icon={faPhone}></FontAwesomeIcon>
                        <span> (+212) 0522-325418</span>
                      </Link>
                    </p>
                    <p>
                      <Link to={"tel:+212522516161">
                        <FontAwesomeIcon icon={faPhone}></FontAwesomeIcon>
                        <span> (+212) 0522-516161 </span>
                      </Link>
                    </p>
                  </li>
                  <li>
                    <p>
                      <Link to={"https://wa.me/+212771525252">
                        <FontAwesomeIcon icon={faWhatsapp}></FontAwesomeIcon>
                        <span> (+212) 771-525252</span>
                      </Link>
                    </p>
                  </li>
                  <li>
                    <p>
                      <Link to={"mailto:clients@maghrebaccessoires.com">
                        clients@maghrebaccessoires.com
                      </Link>
                    </p>
                  </li>
                  <li>
                    <Link to="/contact-us">Nos coordonnées </Link>
                  </li>
                </ul>
              </div>
              <div className="app_img">
                <figure className="app_img">
                  <Link to="/">
                    <img src="assets/img/icon/icon-appstore.png" alt="" />
                  </Link>
                </figure>
                <figure className="app_img">
                  <Link to="/">
                    <img src="assets/img/icon/icon-googleplay.png" alt="" />
                  </Link>
                </figure>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-3 col-sm-5">
            <div className="widgets_container widget_menu">
              <h3>Politiques & Infos</h3>
              <div className="footer_menu">
                <ul>
                  <li>
                    <Link to="/terms-conditions">Termes et conditions</Link>
                  </li>
                  <li>
                    <Link to="/terms-conditions">Mode de livraison</Link>
                  </li>
                  <li>
                    <Link to="/terms-conditions">Politique de retour</Link>
                  </li>
                  <li>
                    <Link to="/terms-conditions">Modes de paiement</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6">
            <div className="widgets_container widget_menu">
              <h3>Liens rapides</h3>
              <div className="footer_menu">
                <ul>
                  <li>
                    <Link to="/catalog">Catalogue Web</Link>
                  </li>
                  <li>
                    <Link to="/catalog">Nos produits</Link>
                  </li>
                  <li>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        return dispatch({
                          type: TABS_ACTIONS.HISTORY_PUSH,
                          payload: {
                            screen: SCREENS.CATALOG,
                            props: {
                              tab: "vehicles",
                            },
                          },
                        });
                      }}
                    >
                      Nos marques
                    </span>
                  </li>
                  <li>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        return dispatch({
                          type: TABS_ACTIONS.HISTORY_PUSH,
                          payload: {
                            screen: SCREENS.CATALOG,
                            props: {
                              tab: "manufacturers",
                            },
                          },
                        });
                      }}
                    >
                      Nos partenaires
                    </span>
                  </li>
                  <li>
                    <Link to="/">FAQ</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-5 col-sm-6">
            <div className="widgets_container widget_menu">
              <h3>Mon compte</h3>
              <div className="footer_menu">
                <ul>
                  <li>
                    <Link to="/invoices">Mes factures</Link>
                  </li>
                  <li>
                    <Link to="/Linkssets">Mes avoirs</Link>
                  </li>
                  <li>
                    <Link to="/orders">Mes commandes</Link>
                  </li>
                  <li>
                    <Link to="/profile/me">Mon compte</Link>
                  </li>
                  <li>
                    <Link to="/wish-list-items">Liste des souhaits</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-7 col-sm-12">
            <div className="widgets_container">
              <h3>Réseaux sociaux</h3>
              <div className="footer_contact"></div>
              <div className="footer_social">
                <ul>
                  <li>
                    <Link
                      className="social facebook"
                      to={"https://www.facebook.com/maghrebaccessoires"
                    >
                      <FontAwesomeIcon icon={faFacebook}></FontAwesomeIcon>
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="social youtube"
                      to={"https://www.youtube.com/channel/UCqXyw6OvjYDROIsabqW0iXQ"
                    >
                      <FontAwesomeIcon icon={faYoutube}></FontAwesomeIcon>
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="social linkedin"
                      to={"https://www.linkedin.com/in/Linkutoplus-maghrebaccessoires-269bab1b2/"
                    >
                      <FontAwesomeIcon icon={faLinkedin}></FontAwesomeIcon>
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="social instagram"
                      to={"https://www.instagram.com/Linkutoplus_maghrebaccessoires/"
                    >
                      <FontAwesomeIcon icon={faInstagram}></FontAwesomeIcon>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BottomFooter />
    </div> */
  );
};
