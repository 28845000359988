import { faBars, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";
import { Container, Dropdown } from "react-bootstrap";

const Toggler = ({ title, items }) => {
  return (
    <>
      <Dropdown style={{ width: "100%" }}>
        <Dropdown.Toggle
          variant=""
          id="dropdown-basic"
          className="categories_title categories_toggle"
        >
          <div>
            <FontAwesomeIcon icon={faBars} />
            <span
              className="m-left-20"
              style={{
                // fontSize: "18px",
                fontWeight: "600",
              }}
            >
              {title}
            </span>
          </div>
          <div>
            <FontAwesomeIcon icon={faChevronDown} />
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className="our_products__select">
          <Container>{items}</Container>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

Toggler.propTypes = {
  items: PropTypes.any,
  title: PropTypes.string.isRequired,
};

Toggler.defaultProps = {
  // items: [],
};

export default Toggler;
