import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { Button, Collapse, Input, List, Spin } from "antd";
import { difference, isEmpty, uniqBy } from "lodash";
import get from "lodash/get";
import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { TabContext } from "../../../../../../Context/Tab";
import DirectArticle from "../../../../../../entities/direct-article";
import Loader from "../../../../../../shared/components/Loader";
import { ManufacturersQueries } from "../../../../../../shared/graphql/queries";
import {
  DEFAULT_VEHICLES,
  SCREENS,
  SEARCH_PARTS,
  TABS_ACTIONS,
} from "../../../../../../utils/constants";
const { Panel } = Collapse;
const { SEARCH, ARTICLE } = ManufacturersQueries;

const Article = (props) => {
  const [input, setInput] = useState("");

  const {
    groups: {
      criteria: {
        article: { id, code },
      },
    },

    search,
  } = props;
  const elementRef = useRef();
  const [skip, setSkip] = useState(0);
  const { dispatch } = useContext(TabContext);
  const {
    loading: loadingArticles,
    error: errorArticles,
    data: articles,
  } = useQuery(ARTICLE, {
    variables: { isIn: [{ id: get(props, "data[0].id") }] },
  });
  const variables = {
    where: {
      by: "vehicle",
      type: "vehicle",
      criteria: { article: { id, code } },
      skip,
      take: DEFAULT_VEHICLES,
    },
  };
  const { assemblyGroup } = search;

  const [cumulData, setCumulData] = useState([]);
  const [hide, setHide] = useState(false);
  const [getSearch, { loading: loadingSearch, data }] = useLazyQuery(SEARCH);
  useEffect(() => {
    if (data) {
      setCumulData(
        uniqBy([...cumulData, ...get(data, "search.data", [])], "id")
      );
      if (
        isEmpty(
          difference(
            uniqBy([...cumulData, ...get(data, "search.data", [])], "id"),
            cumulData
          )
        )
      ) {
        setHide(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  useEffect(() => {
    getSearch({
      variables,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip]);
  if (loadingArticles) return <Spin></Spin>;

  return (
    <Collapse
      bordered={false}
      // defaultActiveKey={["1"]}
      expandIconPosition={"right"}
    >
      <Panel
        header="Véhicules"
        key="1"
        className="collapse_filter vehicles__sidebar"
      >
        <Input
          style={{ margin: "10px", width: "calc(100% - 20px)" }}
          value={input}
          placeholder={"Rechercher un véhicule"}
          onChange={(_) => {
            setInput(_.target.value);
          }}
        />
        <div
          className="vehicles_scroll"
          style={{ padding: "10px" }}
          ref={elementRef}
        >
          <Fragment>
            <List
              dataSource={cumulData.filter((item) => {
                return input
                  ? get(item, "model.name", "")
                      .toString()
                      .toLowerCase()
                      .includes(input.toLowerCase()) ||
                      get(item, "name", "")
                        .toLowerCase()
                        .includes(input.toLowerCase()) ||
                      get(item, "yearOfConstructionFrom", "")
                        .toString()
                        .toLowerCase()
                        .includes(input.toLowerCase()) ||
                      get(item, "cylinders", "")
                        .toString()
                        .toLowerCase()
                        .includes(input.toLowerCase()) ||
                      get(item, "powerHorsePowerFrom", "")
                        .toString()
                        .toLowerCase()
                        .includes(input.toLowerCase()) ||
                      get(item, "powerHorsePowerTo", "")
                        .toString()
                        .toLowerCase()
                        .includes(input.toLowerCase()) ||
                      get(item, "fuelType", "")
                        .toString()
                        .toLowerCase()
                        .includes(input.toLowerCase())
                  : true;
              })}
              renderItem={(item) => {
                return (
                  <List.Item
                    onClick={() => {
                      console.log("click 30", item);
                      console.log(
                        "click 30",
                        get(articles, "article.data[0].assemblyGroups[0]")
                      );

                      dispatch({
                        type: TABS_ACTIONS.HISTORY_PUSH,
                        payload: {
                          screen: SCREENS.ARTICLES,
                          props: {
                            search: {
                              ...(assemblyGroup
                                ? { assemblyGroup }
                                : {
                                    assemblyGroup: {
                                      id: get(
                                        articles,
                                        "article.data[0].assemblyGroups[0].id"
                                      ),
                                      name: get(
                                        articles,
                                        "article.data[0].assemblyGroups[0].name"
                                      ),
                                    },
                                    name: get(articles, "article.data[0].name"),
                                  }),

                              car: { id: item.id },
                              manufacturerBean: {
                                id: item.manufacturer.id,
                                name: item.manufacturer.name,
                              },
                              modelBean: {
                                id: item.model.id,
                                name: item.model.name,
                              },
                              motorizationBean: {
                                id: item.id,
                                name: item.name,
                              },
                              name: assemblyGroup
                                ? assemblyGroup.name
                                : get(
                                    articles,
                                    "article.data[0].assemblyGroups[0].name"
                                  ),
                            },
                          },
                        },
                      });
                    }}
                  >
                    {`${item.model.name}(${item.name})/${item.yearOfConstructionFrom}/${item.cylinders}/${item.powerHorsePowerFrom}-${item.powerHorsePowerTo}/${item.fuelType}`}
                  </List.Item>
                );
              }}
            />
          </Fragment>
          {loadingSearch && (
            <Spin style={{ width: "100%", textAlign: "center" }} />
          )}
        </div>
      </Panel>
      <div
        className="div-vertical center center-justified"
        style={{ width: "100%" }}
        hidden={hide}
      >
        <Button
          style={{ marginBottom: 15 }}
          type="primary"
          size="medium"
          onClick={() => {
            elementRef.current.scrollTop = elementRef.current.scrollHeight;
            setSkip(skip + 100);
          }}
        >
          Afficher plus
        </Button>
      </div>
    </Collapse>
  );
};

export default React.memo((props) => {
  const { filter, ...rest } = props;
  const {
    groups: { type },
  } = filter;
  if (type === SEARCH_PARTS.ARTICLE)
    return (
      <DirectArticle
        filter={{
          where: {
            type: "REFERENCE",
            reference: get(props, "filter.groups.criteria.article.code"),
          },
          like: {},
        }}
        View={Loader(Article)}
        {...rest}
        groups={filter.groups}
      ></DirectArticle>
    );
  return null;
});
