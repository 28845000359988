import { Popover } from "antd";
import React, { useContext } from "react";
import { ITEM_WAREHOUSE_COLOR } from "../../../../../utils/constants";
import { getItemWareHousesAvailibity } from "../../../../../utils/helpers";
import { StoreContext } from "../../../../store";

export default ({
  item,
  itemWareHouses = [],

  quantity,
}) => {
  const { user = {} } = useContext(StoreContext);

  const wareHouses = itemWareHouses.filter((item) => item.quantity > 0);

  const cities = wareHouses.map(
    ({ quantity, wareHouse }) =>
      wareHouse.city +
      (user.role.name === "admin" ||
      user.role.name === "commercial" ||
      user.role.name === "commercial régional"
        ? " (" + quantity + " unités)"
        : "")
  );

  return (
    <Popover
      placement="left"
      content={cities.join(" - ")}
      zIndex="1500"
      trigger="hover"
    >
      <div
        style={{
          width: 25,
          height: 25,
          backgroundColor:
            ITEM_WAREHOUSE_COLOR[
              getItemWareHousesAvailibity(
                itemWareHouses,
                quantity,
                item.id === item.catalogCode
              )
            ],
          borderRadius: "50%",
        }}
      />
    </Popover>
  );
};
