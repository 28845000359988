import gql from "graphql-tag";

export const ORDERS = gql`
  query Orders(
    $where: OrderWhereInput
    $isNotIn: [OrderWhereInput]
    $skip: Int
    $like: OrderWhereInput
    $take: Int
    $gt: OrderWhereInput
    $lt: OrderWhereInput
    $gte: OrderWhereInput
    $lte: OrderWhereInput
  ) {
    orders(
      where: $where
      skip: $skip
      isNotIn: $isNotIn
      take: $take
      like: $like
      gt: $gt
      lt: $lt
      gte: $gte
      lte: $lte
    ) {
      data {
        id
        invoice {
          id
          expeditionNumber
        }
        replayed
        reference
        isUrgent
        cartItem {
          id
        }
        source
        createdDate
        date
        status
        amount
        transporter {
          id
          name
        }
        note {
          id
          body
        }
        tag
        orderLines {
          id
          price
          amount
          discount
          itemId
          description
          quantity
          wareHouse {
            id
            name
          }
        }
        account {
          id
          title
        }
      }
      count
    }
  }
`;

export const ORDER = gql`
  query Order($where: OrderWhereInput) {
    order(where: $where) {
      id
      source
      invoice {
        id
        expeditionNumber
      }
      replayed
      reference
      isUrgent
      createdDate
      date
      cartItem {
        id
      }
      status
      amount
      transporter {
        id
        name
      }
      note {
        id
        body
      }
      tag
      orderLines {
        id
        price
        amount
        discount
        itemId
        description
        quantity
        wareHouse {
          id
          name
        }
      }
    }
  }
`;

export default {
  ORDERS,
  ORDER,
};
