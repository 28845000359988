import { useMutation } from "@apollo/react-hooks";
import { Icon } from "antd";
import cuid from "cuid";
import React, { useContext, useState } from "react";
import {
  CartItemMutations,
  ItemMutations,
} from "../../../../../shared/graphql/mutations";
import { CartItemsQueries } from "../../../../../shared/graphql/queries";
import {
  DEFAULT_PAGE_SIZE_CART,
  ITEM_WAREHOUSE_COLOR,
} from "../../../../../utils/constants";
import { getItemWareHousesAvailibity } from "../../../../../utils/helpers";
import { StoreContext } from "../../../../store";

const { CREATE_ITEM } = ItemMutations;
const { CREATE_CART_ITEM } = CartItemMutations;
const { CART_ITEMS } = CartItemsQueries;

export default ({
  code,
  itemId,
  itemName,
  openNotification,
  itemWareHouses,
  itemWareHousesQuantity,
}) => {
  const [quantity, setQuantity] = useState(1);
  const { user = {} } = useContext(StoreContext);

  const [addItem] = useMutation(CREATE_ITEM, {
    onCompleted: ({ createItem: { id } }) =>
      addCartItem({
        variables: {
          data: {
            item: {
              id,
            },
            accountDestinationId: user.account.id,

            quantity,
            id: cuid(),
          },
        },
      }),
  });
  const handleCartPress = () => {
    itemId === -1
      ? addItem({
          variables: {
            data: {
              id: code,
              code,
            },
          },
        })
      : addCartItem({
          variables: {
            data: {
              item: {
                id: itemId,
              },
              accountDestinationId: user.account.id,
              quantity,
              id: cuid(),
            },
          },
        });
  };

  const addCartItemMutation = useMutation(CREATE_CART_ITEM, {
    update: (cache, { data: { createCartItem } }) => {
      const {
        cartItems,
        cartItems: { data, count },
      } = cache.readQuery({
        query: CART_ITEMS,
        variables: {
          where: { deleted: false },
          take: DEFAULT_PAGE_SIZE_CART,
        },
      });
      cache.writeQuery({
        query: CART_ITEMS,
        variables: {
          where: { deleted: false },
          take: DEFAULT_PAGE_SIZE_CART,
        },
        data: {
          cartItems: {
            ...cartItems,
            data: [createCartItem, ...data],
            count: count + 1,
          },
        },
      });
    },
    onCompleted: () => {
      openNotification("success");
    },
  });
  const [addCartItem] = addCartItemMutation;

  return (
    <Icon
      style={{
        fontSize: 20,
        margin: 5,
        color:
          ITEM_WAREHOUSE_COLOR[
            getItemWareHousesAvailibity(
              itemWareHouses,
              quantity,
              code === itemId
            )
          ],
      }}
      type={addCartItemMutation.loading ? "loading" : "shopping-cart"}
      onClick={(e) => {
        e.stopPropagation();
        handleCartPress();
      }}
    />
  );
};
