import { Dropdown, Tooltip } from "antd";
import find from "lodash/find";
import isEmpty from "lodash/isEmpty";
import React, { useContext, useEffect, useState } from "react";
import { StoreContext } from "../../../../../../../shared/store";
import { WAREHOUSES_CITIES } from "../../../../../../../utils/constants";
import {
  convertEnglishDateToDateString,
  numberFormatter,
} from "../../../../../../../utils/formatter-functions";

const Menu = ({ wareHouses }) => {
  // grantPrivateAccess

  return (
    <div
      className="div-horizontal"
      style={{
        flexWrap: "wrap",
        background: "white",
        maxWidth: "520px",
        overflowY: "auto",
        maxHeight: "500px",
      }}
    >
      {wareHouses.map((wareHousesItem, index) => (
        <MenuItem
          wareHouses={wareHousesItem}
          key={index}
          index={index}
          count={wareHouses.length}
        />
      ))}
    </div>
  );
};

const MenuItem = ({
  index,
  count,
  wareHouses: {
    wareHouse: { name, city },
    quantity,
  },
}) => {
  const { user = {} } = useContext(StoreContext);
  // // console.log("user context", user);
  // // console.log("user context role name", user.role.name);
  // console.log("index", index);
  // console.log("count", count);

  return (
    <div
      className="div-vertical stock_par_magasin"
      style={{ width: index < count - 1 || count % 2 == 0 ? "50%" : "100%" }}
    >
      <div className="div-horizontal center">
        <span
          className={
            quantity == 0
              ? `ant-dropdown-link warehouse--dropdown warehouse--inactif`
              : `ant-dropdown-link warehouse--dropdown warehouse--actif`
          }
          onClick={(e) => e.preventDefault()}
        ></span>

        <label className="ville">{city}</label>
      </div>
      <label className="quantite">
        {user.role.name === "admin" ||
        user.role.name === "commercial" ||
        user.role.name === "commercial régional"
          ? "Quantité disponible : " +
            numberFormatter(quantity, 0) +
            " unité" +
            (quantity > 1 ? "s" : "")
          : ""}
      </label>

      <label
        className={
          "disponibilite " + (quantity > 0 ? "disponible" : "indisponible")
        }
      >
        {quantity > 0 ? "Disponible" : "Indisponible"}
      </label>
    </div>
  );
};

const WareHouse = ({ itemWareHouses, prices, dateArrivage, icon }) => {
  // // console.log("dateArrivage", dateArrivage);
  const wareHouses = itemWareHouses;
  //.filter((item) => item.quantity > 0);
  const [menu, setMenu] = useState(Menu({ wareHouses }));

  const isAvailable = () =>
    find(wareHouses, function (item) {
      return (
        item.wareHouse.city.toLowerCase() ===
        WAREHOUSES_CITIES.DEFAULT_CITY.toLowerCase()
      );
    });

  const isAvailableSecond = () =>
    find(wareHouses, function (item) {
      return (
        item.wareHouse.city.toLowerCase() ===
        WAREHOUSES_CITIES.DEFAULT_CITY_SECOND.toLowerCase()
      );
    });

  useEffect(() => {
    if (!wareHouses) setMenu("");
  }, [wareHouses]);

  if (isEmpty(wareHouses) && !isEmpty(prices))
    return (
      <span className="ant-dropdown-link warehouse--dropdown warehouse--inactif">
        {dateArrivage ? (
          <Tooltip
            title={
              "Arrivage prévu pour le " +
              convertEnglishDateToDateString(dateArrivage)
            }
          >
            {convertEnglishDateToDateString(dateArrivage)}
          </Tooltip>
        ) : (
          "En rupture"
        )}
      </span>
    );

  if (isEmpty(prices)) {
    return (
      <span className="ant-dropdown-link warehouse--dropdown warehouse--non-gere">
        Non géré
      </span>
    );
  }

  return (
    <Dropdown overlay={menu} placement={"bottomRight"}>
      <span
        className={
          !(isAvailable() || isAvailableSecond())
            ? `ant-dropdown-link warehouse--dropdown warehouse--inactif--default--city`
            : `ant-dropdown-link warehouse--dropdown warehouse--actif`
        }
        onClick={(e) => e.preventDefault()}
      >
        Entrepôts
      </span>
    </Dropdown>
  );
};
export default WareHouse;
