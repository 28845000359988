import gql from "graphql-tag";

export const ADD_PERTE_VENTE = gql`
  mutation addPerteVente($addPerteVenteInput: AddPerteVenteInput) {
    addPerteVente(addPerteVenteInput: $addPerteVenteInput) {
      message
    }
  }
`;

export const DELETE_PERTE_VENTE = gql`
  mutation deletePerteVente($deletePerteVenteInput: DeletePerteVenteInput) {
    deletePerteVente(deletePerteVenteInput: $deletePerteVenteInput) {
      message
    }
  }
`;
