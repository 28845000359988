import { useQuery } from "@apollo/react-hooks";
import { Button, Checkbox, Collapse, Input, List } from "antd";
import isEmpty from "lodash/isEmpty";
import React, { useEffect, useState } from "react";
import { cacheQueries } from "../../../../../../shared/graphql/queries";
import { OEMS } from "../../../../../../utils/constants";
const { Panel } = Collapse;
const { LOCAL_BRANDS } = cacheQueries;

const Article = (props) => {
  const Picker = {
    MDD: ({ item, oems, setOems, setInput }) => (
      <List.Item>
        <Checkbox
          onChange={() => {}}
          checked={oems.includes(item.name)}
          onClick={() => {
            if (
              brandsOurs.filter((item1) => item1.name === item.name).length > 0
            )
              setInput("");
            oems.includes(item.name)
              ? setOems(oems.filter((oem) => oem !== item.name))
              : setOems([...oems, item.name]);
          }}
        >
          <span
            className={
              "listStyle" +
              (brandsOurs.filter((item1) => item1.name === item.name).length > 0
                ? " our-brand"
                : "")
            }
          >
            {item.name.charAt(0).toUpperCase() +
              item.name.toLowerCase().slice(1)}
          </span>
        </Checkbox>
      </List.Item>
    ),
    DEFAULT: ({ item, oems, setOems, setInput }) => (
      <List.Item>
        <Checkbox
          checked={oems.includes(item.name)}
          onClick={() => {
            setInput("");
            oems.includes(item.name)
              ? setOems(oems.filter((oem) => oem !== item.name))
              : setOems([...oems, item.name]);
          }}
        >
          <span className="listStyle">
            {item.name.charAt(0).toUpperCase() +
              item.name.toLowerCase().slice(1)}
          </span>
        </Checkbox>
      </List.Item>
    ),
  };

  const { brandsOurs, brandsOthers } = props;

  const [input, setInput] = useState("");
  const [allShown, setAllShown] = useState(false);

  const where = props.search || {};
  const { data: ours } = useQuery(LOCAL_BRANDS, {
    variables: {
      where: { ours: true, ...where },
      isIn: {
        ...(!isEmpty(props.assemblyGroups) && {
          assemblyGroups: props.assemblyGroups,
        }),
      },
    },
    fetchPolicy: "cache-first",
  });

  const { data: others } = useQuery(LOCAL_BRANDS, {
    variables: {
      where: { ours: false, ...where },
      isIn: {
        ...(!isEmpty(props.assemblyGroups) && {
          assemblyGroups: props.assemblyGroups,
        }),
      },
    },
    fetchPolicy: "cache-first",
  });

  // const brandsOurs = get(ours, "localBrands.data", []);
  // const brandsOthers = get(others, "localBrands.data", []);

  const { oems, setOems } = props;

  useEffect(() => {
    setInput("");
    /*  setOems(
      oems.filter((oem) =>
        [...brandsOthers, ...brandsOurs].map(({ name }) => name).includes(oem)
      )
    ); */
  }, []);
  return (
    <Collapse
      defaultActiveKey={["1"]}
      expandIconPosition={"right"}
      bordered={false}
    >
      <Panel header="Équipementiers" key="1" className="collapse_filter">
        <div className="widget_list widget_categories">
          <div style={{ margin: "16px 10px" }}>
            <Input
              value={input}
              placeholder={"Rechercher un équipementier"}
              onChange={(_) => {
                setInput(_.target.value);
              }}
            />
          </div>
          <div className="vehicles_scroll" style={{ padding: "10px" }}>
            <List
              className="empty_list_cross"
              style={{ marginTop: "12px" }}
              grid={{ gutter: 14, column: 2 }}
              loadMore={
                [...brandsOurs, ...brandsOthers].length > 10 &&
                !allShown && (
                  <div
                    style={{
                      textAlign: "center",
                      marginTop: 12,
                    }}
                  >
                    <Button
                      type="primary"
                      size="medium"
                      onClick={() => setAllShown(true)}
                    >
                      Afficher plus
                    </Button>
                  </div>
                )
              }
              dataSource={[
                ...brandsOurs.filter(({ name }) =>
                  input
                    ? name.toLowerCase().includes(input.toLowerCase())
                    : true
                ),
                ...brandsOthers

                  .filter(({ name }) =>
                    input
                      ? name.toLowerCase().includes(input.toLowerCase())
                      : true
                  )
                  .slice(0, allShown ? brandsOthers.length : 10),
              ]}
              renderItem={(item) => {
                const Component = Picker[OEMS.MDD];

                return (
                  <Component
                    oems={props.oems}
                    setOems={props.setOems}
                    item={item}
                    setInput={setInput}
                  />
                );
              }}
            />
          </div>

          {/*   <List
            className="empty_list_cross"
            grid={{ gutter: 16, column: 2 }}
            dataSource={brandsOthers

              .filter(({ name }) =>
                input ? name.toLowerCase().includes(input.toLowerCase()) : true
              )
              .slice(0,allShown ? brandsOthers.length : 10)}
            loadMore={
              !allShown && (
                <div
                  style={{
                    textAlign: "center",
                    marginTop: 12,
                    height: 32,
                    lineHeight: "32px",
                  }}
                >
                  <Button onClick={() => setAllShown(true)}>
                    Afficher plus
                  </Button>
                </div>
              )
            }
            renderItem={(item) => {
              const Component = Picker[OEMS.DEFAULT];
              return (
                <Component
                  oems={props.oems}
                  setOems={props.setOems}
                  item={item}
                  setInput={setInput}
                />
              );
            }}
          /> */}
        </div>
      </Panel>
    </Collapse>
  );
};

export default React.memo(Article);
