import gql from "graphql-tag";

export const UPDATE_COMMERCIAL = gql`
  mutation updateCommercial($updateCommercialInput: UpdateCommercialInput) {
    updateCommercial(updateCommercialInput: $updateCommercialInput) {
      message
    }
  }
`;

export const IMPORT_COMMERCIALS = gql`
  mutation importCommercials($importCommercialsInput: ImportCommercialsInput) {
    importCommercials(importCommercialsInput: $importCommercialsInput) {
      message
    }
  }
`;

export default {
  UPDATE_COMMERCIAL,
};
