import gql from "graphql-tag";

export const ASSETS = gql`
  query Assets(
    $where: AssetWhereInput
    $like: AssetWhereInput
    $skip: Int
    $take: Int
    $gt: AssetWhereInput
    $lt: AssetWhereInput
    $gte: AssetWhereInput
    $lte: AssetWhereInput
  ) {
    assets(
      where: $where
      like: $like
      skip: $skip
      take: $take
      gt: $gt
      lt: $lt
      gte: $gte
      lte: $lte
    ) {
      data {
        id
        amount
        reference
        createdDate
        date
        account {
          id
          title
        }
        assetLines {
          comment
          id
          price
          amount
          discount
          quantity
          item {
            id
            code
          }
        }
      }
      count
    }
  }
`;

export const ASSET = gql`
  query Asset($where: AssetWhereInput) {
    asset(where: $where) {
      id
      amount
      reference
      createdDate
      date
      assetLines {
        comment
        id
        price
        amount
        discount
        quantity
        item {
          id
          code
        }
      }
    }
  }
`;

export default {
  ASSETS,
  ASSET,
};
