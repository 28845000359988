import gql from "graphql-tag";

export const STATEMENTS = gql`
  query Statements(
    $where: StatementWhereInput
    $like: StatementWhereInput
    $skip: Int
    $take: Int
    $gt: StatementWhereInput
    $lt: StatementWhereInput
    $gte: StatementWhereInput
    $lte: StatementWhereInput
  ) {
    statements(
      where: $where
      like: $like
      skip: $skip
      take: $take
      gt: $gt
      lt: $lt
      gte: $gte
      lte: $lte
    ) {
      data {
        id
        asset {
          createdDate
          amount
          reference
          id
        }
        account {
          id
          title
          referent
        }
        invoice {
          createdDate
          amount
          reference
          id
        }
        createdDate
      }
      count
    }
  }
`;

export default {
  STATEMENTS,
};
