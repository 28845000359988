import { Button } from "antd";
import get from "lodash/get";
import isNil from "lodash/isNil";
import moment from "moment";
import React, { useContext, useState } from "react";
import { TabContext } from "../../../../../Context/Tab";
import { ManufacturersQueries } from "../../../../../shared/graphql/queries";
import { SCREENS, TABS_ACTIONS } from "../../../../../utils/constants";
import QuerySelect from "./QuerySelect";
import WithValidation from "./WithValidation";

const { MANUFACTURERS, MODELS, VEHICLES } = ManufacturersQueries;

const ProtectedQuerySelect = WithValidation(QuerySelect);

export default (props) => {
  const { dispatch } = useContext(TabContext);

  const [manufacturerId, setManufacturerId] = useState(null);
  const [manufacturer, setManufacturer] = useState(null);

  const [modelId, setModelId] = useState(null);
  const [motorizationId, setMotorizationId] = useState(null);

  const [model, setModel] = useState(null);
  const [motorization, setMotorization] = useState(null);

  const setManufacturerParent = (manufacturer) => {
    setManufacturer(manufacturer);
    setManufacturerId(manufacturer.id);
  };

  const setModelParent = (model) => {
    setModel(model);
    setModelId(model.id);
  };

  const setMotorizationParent = (motorization) => {
    setMotorization(motorization);
    setMotorizationId(motorization.id);
  };

  const manufactureVariables = { where: {} };
  const modelVariables = isNil(manufacturerId)
    ? { where: {} }
    : { where: { manufacturer: { id: manufacturerId } } };
  const motorizationVariables =
    isNil(manufacturerId) || isNil(modelId)
      ? { where: {} }
      : {
          where: {
            manufacturer: { id: manufacturerId },
            model: { id: modelId },
          },
        };

  const button = isNil(motorizationId)
    ? {
        disabled: true,
        title: "Merci de choisir",
      }
    : {
        disabled: false,
        title: "Valider",
      };
  return (
    <div>
      <div
        style={{
          flexDirection: "row",
          flex: 1,
          alignContent: "center",
          justifyContent: "center",
        }}
      >
        {[
          {
            id: 1,
            query: MANUFACTURERS,
            variables: manufactureVariables,
            placeholder: "Choisir la marque",
            onChange: setManufacturerParent,
            accessor: "manufacturers",
            accessorFn: (data) => get(data, `manufacturers.data`, []),
          },
          {
            id: 2,
            query: MODELS,
            variables: modelVariables,
            placeholder: "Choisir le modèle",
            onChange: setModelParent,
            accessor: "models",
            accessorFn: (data) => {
              const models = get(data, `models.data`, []);
              return models.map((model) => ({
                id: model.id,
                name: `${model.name}/ ${moment(
                  model.yearOfConstructionFrom,
                  "YYYYMM"
                ).format("YYYY")} 
                      -
                      ${moment(model.yearOfConstructionTo, "YYYYMM").format(
                        "YYYY"
                      )}`,
              }));
            },
          },
          {
            id: 3,
            query: VEHICLES,
            variables: motorizationVariables,
            placeholder: "Choisir la motorisation",
            onChange: setMotorizationParent,
            accessor: "vehicles",
            accessorFn: (data) => {
              const vehicles = get(data, `vehicles.data`, []);
              return vehicles.map((vehicle) => ({
                id: vehicle.id,
                name: `${vehicle.name}/${vehicle.powerHorsePowerFrom}-${
                  vehicle.powerHorsePowerTo
                }cv, ${vehicle.motorType}, moteurs: [${vehicle.motors.join(
                  ","
                )}]`,
              }));
            },
          },
        ].map((prop) => (
          <div style={{ marginTop: 15 }} key={`query-select-${prop.id}`}>
            <ProtectedQuerySelect {...prop} />
          </div>
        ))}
      </div>
      <Button
        className="button-form-full"
        disabled={button.disabled}
        type="primary"
        size={"medium"}
        onClick={() => {
          console.log("dispatch 7 *******");

          return dispatch({
            type: TABS_ACTIONS.HISTORY_PUSH,
            payload: {
              screen: SCREENS.ASSEMBLYGROUPS,
              props: {
                search: {
                  linkingTarget: motorizationId,
                  manufacturer: manufacturerId,
                  model: modelId,

                  motorizationBean: motorization,
                  manufacturerBean: manufacturer,
                  modelBean: model,
                },
              },
            },
          });
        }}
      >
        {button.title}
      </Button>
    </div>
  );
};
