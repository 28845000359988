import { Icon } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { Vertical } from "../../Menus";
import SearchBar from "../../Searchbar";
export default ({ title, horizontal, vertical, buttons, ...rest }) => {
  // console.log("vertical", vertical);

  return (
    <div className="header_bottom">
      <div
        className="container div-horizontal center"
        style={{ height: "100%" }}
      >
        <div
          className="div-horizontal center"
          style={{ minWidth: 220, width: "18%" }}
        >
          <div className="header_addons">
            <Link to="/catalog">
              <Icon type="car" />
            </Link>
          </div>
          <Vertical title={title} {...vertical} />
        </div>

        <div style={{ margin: "0px 30px" }} className="div-horizontal growed">
          <SearchBar {...rest} />
        </div>
        <div className="header_configure_area">
          {buttons.map(({ element, key }) => (
            <div className="header_addons" key={key}>
              {element}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
