import gql from "graphql-tag";

export const CREATE_CART_ITEM = gql`
  mutation CreateCartItem($data: CartItemWhereInput) {
    createCartItem(data: $data) {
      id
      createdDate
      cart {
        id
        amount
        account {
          id
          title
        }
      }
      quantity
      item {
        id
        code
      }
      status
      deleted
    }
  }
`;
export const UPDATE_CART_ITEM = gql`
  mutation UpdateCartItem($data: CartItemWhereInput) {
    updateCartItem(data: $data) {
      id
      createdDate
      cart {
        id
        amount
        account {
          id
          title
        }
      }
      quantity
      item {
        id
        code
      }
      status
      deleted
    }
  }
`;

export const UPDATE_CART_ITEM_ACCOUNT_DESTINATION = gql`
  mutation updateCartItemAccountDestination(
    $updateCartItemAccountDestinationInput: UpdateCartItemAccountDestinationInput
  ) {
    updateCartItemAccountDestination(
      updateCartItemAccountDestinationInput: $updateCartItemAccountDestinationInput
    ) {
      message
    }
  }
`;

export const DELETE_CART_ITEM = gql`
  mutation DeleteCartItem($data: CartItemWhereInput) {
    deleteCartItem(data: $data) {
      id
    }
  }
`;

export const DELETE_CART_ITEMS = gql`
  mutation DeleteCartItems($items: [CartItemWhereInput]) {
    deleteCartItems(items: $items) {
      data {
        id
      }
    }
  }
`;

export const CREATE_CART_ITEMS = gql`
  mutation CreateCartItems($items: [CartItemWhereInput]) {
    createCartItems(items: $items) {
      id
      createdDate
      cart {
        id
        amount
        account {
          id
          title
        }
      }
      quantity
      item {
        id
        code
      }
      status
      deleted
    }
  }
`;

export default {
  CREATE_CART_ITEMS,
  CREATE_CART_ITEM,
  DELETE_CART_ITEM,
  UPDATE_CART_ITEM,
  DELETE_CART_ITEMS,
};
