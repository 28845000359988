import find from "lodash/find";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import {
  PATHS,
  ROLES,
  STOCK_STATUS,
  WAREHOUSES_AVAILABILITIES,
  WAREHOUSES_CITIES,
} from "./constants";

export const createdDates = (range) => {
  console.log("range", range);
  if (!isEmpty(range)) {
    const endDate = new Date(range[1]);
    endDate.setDate(endDate.getDate() + 1);
    const endDateString = endDate.toISOString().substring(0, 10);
    return {
      gt: { createdDate: range[0].substring(0, 10) } || {},
      lt: { createdDate: endDateString } || {},
    };
  }
  return range;
};

export const documentDates = (range) => {
  console.log("range", range);
  if (!isEmpty(range)) {
    const startDate = new Date(range[0]);
    startDate.setDate(startDate.getDate() - 1);
    const startDateString = startDate.toISOString().substring(0, 10);

    const endDate = new Date(range[1]);
    endDate.setDate(endDate.getDate() + 1);
    const endDateString = endDate.toISOString().substring(0, 10);
    return {
      gt: { date: startDateString } || {},
      lt: { date: endDateString } || {},
    };
  }
  return range;
};

export const getPageFromSkipAndTake = (skip, take) =>
  parseInt(parseInt(skip) / parseInt(take) + 1);

export const formatPrice = (price) => {
  const priceNum = typeof price === "number" ? price : parseFloat(price);
  return priceNum.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
};

export const randHex = (len = 12) => {
  const maxlen = 8;
  const min = Math.pow(16, Math.min(len, maxlen) - 1);
  const max = Math.pow(16, Math.min(len, maxlen)) - 1;
  const n = Math.floor(Math.random() * (max - min + 1)) + min;
  let r = n.toString(16);
  while (r.length < len) {
    r = r + randHex(len - maxlen);
  }
  return r;
};

export const isAuthenticated = ({ user, access_token }) =>
  user && Object.keys(user).length !== 0 && access_token;

export const jwtDecode = (token) => JSON.parse(atob(token.split(".")[1]));

const defaultPropHandler = { get: (obj, prop) => obj[prop] || obj.default };

const userHomeConfig = {
  [ROLES.ADMIN]: PATHS.ADMIN,
  default: PATHS.HOME,
};

export const userHomeProxy = new Proxy(userHomeConfig, defaultPropHandler);

export const priceAndDiscount = ({
  quantity = 1,
  prices,
  discount = { discountLines: [] },
  account: { id: accountId },
}) => {
  const now = new Date();
  const discountFilter = (lines) =>
    lines.filter(({ min, max }) => quantity <= max && quantity >= min);
  const discountAccountFilter = (lines) =>
    lines.filter(
      ({ startDate, endDate }) =>
        new Date(parseInt(startDate)) <= now &&
        now <= new Date(parseInt(endDate))
    );
  const discountHandler = (element) => {
    const appliedDiscount = get(element, "value", 0);
    const discount =
      get(element, "value", 0) > 0
        ? (65 / 100) * ((100 - appliedDiscount) / 100)
        : 65 / 100;
    return {
      original: parseFloat(price * 1.2).toFixed(2),
      price: parseFloat(discount * price * 1.2).toFixed(2),
      formula: `35% + ${parseInt(appliedDiscount).toFixed(0)}%`,
    };
  };
  if (isEmpty(prices)) return { price: "-", formula: "-" };
  const price = prices.reduce((sum, { value }) => sum + parseFloat(value), 0);
  const withAccount = get(discount, "discountLines", []).filter(
    ({ account }) => !isNil(account) && account.id === accountId
  );

  const [element] = isEmpty(withAccount)
    ? discountFilter(
        get(discount, "discountLines", []).filter(({ account }) =>
          isNil(account)
        )
      )
    : discountAccountFilter(withAccount);
  return discountHandler(element);
};
export const getAmountSavedAndTotal = (records, account) => {
  return {
    amount: Object.values(records).reduce(
      (acc, value) => {
        const prices = priceAndDiscount({
          quantity: value.quantity,
          prices: value.item.prices,
          discount: value.item.discount,
          account: account,
        });
        acc.total =
          acc.total +
          (prices.price === "-"
            ? 0.0
            : parseFloat(prices.price) * value.quantity);

        acc.saved =
          acc.saved +
          (get(prices, "original", 0.0) * value.quantity -
            (prices.price === "-"
              ? 0.0
              : parseFloat(prices.price) * value.quantity));

        return { saved: acc.saved, total: acc.total };
      },
      { saved: 0, total: 0 }
    ),
  };
};
export const getItemWareHousesAvailibity = (
  itemWareHouses,
  quantity,
  nonGere
) => {
  if (isEmpty(itemWareHouses) && nonGere)
    return WAREHOUSES_AVAILABILITIES.EMPTY;

  const available = () =>
    find(itemWareHouses, (item) => {
      return (
        item.quantity > 0 &&
        item.wareHouse.city.toLowerCase() === WAREHOUSES_CITIES.DEFAULT_CITY
      );
    });

  const available2 = () =>
    find(itemWareHouses, (item) => {
      return (
        item.quantity > 0 &&
        item.wareHouse.city.toLowerCase() ===
          WAREHOUSES_CITIES.DEFAULT_CITY_SECOND
      );
    });

  if (isEmpty(itemWareHouses)) return WAREHOUSES_AVAILABILITIES.UNAVAILABLE;

  const quantityAvailaible = itemWareHouses.reduce(
    (acc, { quantity }) => acc + quantity,
    0
  );

  if (quantityAvailaible === 0) return WAREHOUSES_AVAILABILITIES.UNAVAILABLE;

  if (quantity <= quantityAvailaible && !(available() || available2()))
    return WAREHOUSES_AVAILABILITIES.AVAILABLE_IN_DEFAULT_CITY;

  return quantity <= quantityAvailaible
    ? WAREHOUSES_AVAILABILITIES.AVAILABLE
    : WAREHOUSES_AVAILABILITIES.EXCEEDED;
};

export const excelDateToJSDate = (serial) => {
  var utcDays = Math.floor(serial - 25569);
  var utcValue = utcDays * 86400;
  var date = new Date(utcValue * 1000);
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};

export const format = (value) =>
  (value || "")
    .toString()
    .toLowerCase()
    .replace(new RegExp(`[${[" ", ".", "_", "-"].join("")}]`, "g"), "");

export const guid = () => {
  const nav = window.navigator;
  const screen = window.screen;
  let guid = nav.mimeTypes.length;
  guid += nav.userAgent.replace(/\D+/g, "");
  guid += nav.plugins.length;
  guid += screen.height || "";
  guid += screen.width || "";
  guid += screen.pixelDepth || "";
  return guid;
};

export const getItemWareHousesStatus = (itemWareHouses, quantity) => {
  const stockquantity = itemWareHouses.reduce(
    (acc, curr) => acc + curr.quantity,
    0
  );

  const isAvailable = find(itemWareHouses, function (item) {
    return (
      item.quantity > 0 &&
      item.wareHouse.city.toLowerCase() === WAREHOUSES_CITIES.DEFAULT_CITY
    );
  });

  const isAvailable2 = find(itemWareHouses, (item) => {
    return (
      item.quantity > 0 &&
      item.wareHouse.city.toLowerCase() ===
        WAREHOUSES_CITIES.DEFAULT_CITY_SECOND
    );
  });

  return stockquantity === 0
    ? STOCK_STATUS.WARNING
    : stockquantity >= quantity && !(isAvailable || isAvailable2)
    ? STOCK_STATUS.AVAILABLE_IN_DEFAULT_CITY
    : stockquantity < quantity
    ? STOCK_STATUS.WARNING_ORANGE
    : STOCK_STATUS.SUCCESS;
};
