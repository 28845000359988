import { useMutation } from "@apollo/react-hooks";
import { Alert, Form, InputNumber, Modal, Select, Spin } from "antd";
import { get } from "lodash";
import React, { useContext, useState } from "react";
import Heading from "../../../../../../shared/components/stories/Heading";
import { ADD_PERTE_VENTE } from "../../../../../../shared/graphql/mutations/perteVente";
import { StoreContext } from "../../../../../../shared/store";
import { MOTIF_PERTE_VENTE } from "../../../../../../utils/constants";
import { numberFormatter } from "../../../../../../utils/formatter-functions";
const { Option } = Select;
const AjouterPerteVenteModal = ({
  setVisible,
  visible,
  article,

  ...rest
}) => {
  const form = rest.form;
  const { getFieldDecorator, getFieldsError } = form;
  const { user } = useContext(StoreContext);

  console.log("article", get(article, "item"));

  const [motif, setMotif] = useState(null);
  const [addPerteVente, { loading: loadingAjouterPerteVente }] = useMutation(
    ADD_PERTE_VENTE,
    {
      onCompleted: () => {
        setVisible(false);
        form.resetFields();
      },
    }
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields((err, values) => {
      console.log("values", values);
      if (!err) {
        addPerteVente({
          variables: {
            addPerteVenteInput: {
              ...values,
              ...(values["concurrentId"] && {
                concurrentId: values["concurrentId"].key,
              }),
              userId: user.id,
              itemId: get(article, "item.id"),
              prixActuel:
                parseFloat(get(article, "item.prices[0].value", "0")) *
                1.2 *
                0.65,
            },
          },
        });
      }
    });
  };

  function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some((field) => fieldsError[field]);
  }

  return (
    <Modal
      className="form-modal"
      centered
      visible={visible}
      onCancel={() => {
        form.resetFields();
        setVisible(false);
      }}
      okButtonProps={{
        disabled: loadingAjouterPerteVente || hasErrors(getFieldsError()),
      }}
      onOk={handleSubmit}
      title={<Heading title={"Signaler une perte de vente"} />}
    >
      <Spin spinning={loadingAjouterPerteVente}>
        <Alert
          style={{ marginBottom: "20px" }}
          message={
            "Signaler une perte de vente pour la référence " +
            get(article, "item.id") +
            " (Prix de vente net: " +
            numberFormatter(
              parseFloat(get(article, "item.prices[0].value", "0")) *
                1.2 *
                0.65,
              2
            ) +
            " DH)"
          }
          type="warning"
          showIcon
        />
        <Form className="login-form">
          <Form.Item label="Motif">
            {getFieldDecorator("motif", {
              rules: [
                {
                  required: true,
                  message: "Le champs Motif est obligatoire",
                },
              ],
            })(
              <Select
                size={"medium"}
                placeholder="Selectionner un motif"
                filterOption={false}
                onChange={(value) => setMotif(value)}
                //   style={{ width: "350px" }}
              >
                {MOTIF_PERTE_VENTE.map((element) => (
                  <Option key={element}>{element}</Option>
                ))}
              </Select>
            )}
          </Form.Item>

          <Form.Item label={"Quantité"}>
            {getFieldDecorator("quantite", {
              rules: [
                {
                  required: true,
                  message: "Le champs Quantité est obligatoire",
                },
                {
                  type: "integer",
                  message: "Le champs Quantité  doit contenir un entier",
                },
              ],
            })(
              <InputNumber
                style={{ width: "100%" }}
                type="number"
                min={0}
                size="medium"
                placeholder={"Quantité"}
              />
            )}
          </Form.Item>

          {motif === "PRIX" && (
            <>
              <Form.Item label={"Prix du marché (NET)"}>
                {getFieldDecorator("prixMarche", {
                  rules: [
                    {
                      required: true,
                      message: "Le champs Prix du marché (NET) est obligatoire",
                    },
                  ],
                })(
                  <InputNumber
                    style={{ width: "100%" }}
                    type="number"
                    min={0}
                    size="medium"
                    placeholder={"Prix du marché (NET)"}
                  />
                )}
              </Form.Item>

              <Form.Item label={"Concurrent"}>
                {getFieldDecorator("concurrentId", {
                  rules: [
                    {
                      required: true,
                      message: "Le champs Concurrent est obligatoire",
                    },
                  ],
                })(
                  <Select
                    labelInValue
                    size={"medium"}
                    placeholder="Selectionner un concurrent"
                    filterOption={false}
                    //   style={{ width: "350px" }}
                  >
                    <Option key={"1"}>{"CONCURRENT_1"}</Option>
                  </Select>
                )}
              </Form.Item>
            </>
          )}
        </Form>
      </Spin>
    </Modal>
  );
};

export default Form.create({ name: "addPerteVente" })(AjouterPerteVenteModal);
