import gql from "graphql-tag";

export const LIGNES_FACTURE = gql`
  query lignesFacture($lignesFactureInput: LignesFactureInput) {
    lignesFacture(lignesFactureInput: $lignesFactureInput) {
      data {
        id
        price
        amount
        quantity
        item {
          id
          catalogCode
          name
          oem
        }
        invoice {
          id
          reference
          createdDate
        }
      }
      count
    }
  }
`;

export const INVOICES = gql`
  query Invoices(
    $where: InvoiceWhereInput
    $like: InvoiceWhereInput
    $skip: Int
    $take: Int
    $gt: InvoiceWhereInput
    $lt: InvoiceWhereInput
    $gte: InvoiceWhereInput
    $lte: InvoiceWhereInput
  ) {
    invoices(
      where: $where
      like: $like
      skip: $skip
      take: $take
      gt: $gt
      lt: $lt
      gte: $gte
      lte: $lte
    ) {
      data {
        id
        expeditionNumber
        amount
        reference
        createdDate
        date
        discount
        paidToDate
        vat
        memo
        account {
          id
          address
          title
        }
        invoiceLines {
          id
          price
          amount
          discount
          itemId
          description
          quantity
        }
      }
      count
    }
  }
`;

export const INVOICE = gql`
  query Invoice($where: InvoiceWhereInput) {
    invoice(where: $where) {
      id
      amount
      reference
      createdDate
      date
      expeditionNumber
      discount
      paidToDate
      vat
      memo
      invoiceLines {
        id
        price
        amount
        discount
        itemId
        description
        quantity
      }
    }
  }
`;

export default {
  INVOICES,
  INVOICE,
  LIGNES_FACTURE,
};
