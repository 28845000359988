import { Collapse, Descriptions, Modal, notification, Spin, Tabs } from "antd";
import get from "lodash/get";
import React, { useState } from "react";
import ItemsEntity from "../../../../../../entities/item";
import Loader from "../../../../../../shared/components/Loader";
import Heading from "../../../../../../shared/components/stories/Heading";
import { ItemWareHousesList } from "../../../../WishList/Items/List/view";

import { Store } from "../../../../../../shared/store";
import { numberFormatter } from "../../../../../../utils/formatter-functions";
import { priceAndDiscount } from "../../../../../../utils/helpers";
import AddToCart from "./AddToCart";
import Characteristics from "./Characteristics";
import Image from "./Image";
import Manufacturers from "./Manufacturers";
import Parts from "./Parts";
const { TabPane } = Tabs;

const openNotification = (type) => {
  notification[type]({
    message: "l'article est ajouté au pannier avec succès",
  });
};

const View = Store((props) => {
  const {
    store: {
      user: { account },
    },
  } = props;
  const [quantity, setQuantity] = useState(1);

  const [imagePopUpConfig, setImagePopUpConfig] = useState({
    hidden: true,
    url: "",
  });

  const {
    article,
    history,

    visible,
    setVisible,
  } = props;

  const closeModal = () => {
    setVisible(!visible);
  };

  const item =
    props.data && get(article, "item.id") !== -1
      ? props.data
      : {
          id: -1,
          prices: [],
          discount: [],
          itemWareHouses: [],
        };

  const { prices, discount, itemWareHouses } = item;

  const { city: clientCity } = account;
  const { Panel } = Collapse;
  return (
    <Modal
      centered
      className="full-screen-modal"
      visible={visible}
      footer={null}
      title={
        <Heading
          title={
            article.item.id === -1
              ? article.code + " (" + article.name + ")"
              : article.item.id + " (" + article.item.name + ")"
          }
        />
      }
      onCancel={closeModal}
    >
      <Tabs defaultActiveKey="1">
        <TabPane className="container tapSwitch" tab="Caractéristiques" key="1">
          <div className="div-vertical">
            <div className="descriptions-div">
              <Collapse
                bordered={false}
                expandIconPosition={"right"}
                defaultActiveKey={["1"]}
              >
                <Panel header={"Détails"} key="1">
                  <Descriptions bordered size="small">
                    <Descriptions.Item label="N° commercial d'article">
                      {article.item.id === -1
                        ? article.item.code
                        : article.item.id}
                    </Descriptions.Item>
                    <Descriptions.Item label="N° article">
                      {article.code}
                    </Descriptions.Item>
                    <Descriptions.Item label="Equipementier">
                      {article.item.oem || article.brand.name}
                    </Descriptions.Item>
                    <Descriptions.Item label="Prix Brut de Vente Unitaire(TTC)">
                      {numberFormatter(
                        priceAndDiscount({
                          quantity,
                          prices: prices,
                          discount: discount,
                          account,
                        }).original,
                        2
                      ) + " Dh"}
                    </Descriptions.Item>

                    <Descriptions.Item label="stock">
                      <ItemWareHousesList
                        prices={prices}
                        itemWareHouses={itemWareHouses}
                        quantity={quantity}
                      />
                    </Descriptions.Item>

                    <Descriptions.Item label="Panier">
                      <AddToCart
                        itemWareHouses={itemWareHouses || []}
                        itemId={article.item.id}
                        itemName={article.item.name}
                        prices={article.item.prices}
                        openNotification={openNotification}
                        history={props.history}
                        clientCity={clientCity}
                        quantity={quantity}
                        setQuantity={setQuantity}
                        code={article.code}
                      ></AddToCart>
                    </Descriptions.Item>
                  </Descriptions>
                </Panel>
              </Collapse>
            </div>

            <Characteristics article={article} history={history} />
          </div>
          <Collapse
            bordered={false}
            expandIconPosition={"right"}
            defaultActiveKey={["1"]}
          >
            <Panel header={"Image"} key="1">
              <Image
                id={article.referenceArticle}
                itemId={article.item.id}
                photo={article.item.photo}
                photoVersion={article.item.photoVersion}
                photo2={article.item.photo2}
                photoVersion2={article.item.photoVersion2}
                setImagePopUpConfig={setImagePopUpConfig}
                oem={article.item.oem}
                mode="carousel"
              ></Image>
            </Panel>
          </Collapse>
        </TabPane>
        <TabPane className="container tapSwitch" tab="Véhicules" key="2">
          <Manufacturers {...props} article={article} />
        </TabPane>
        <TabPane className="container tapSwitch" tab="Liste des pièces" key="3">
          <Parts
            {...props}
            id={article.referenceArticle}
            setImagePopUpConfig={setImagePopUpConfig}
            openNotification={openNotification}
            history={props.history}
            clientCity={clientCity}
            account={account}
          />
        </TabPane>
      </Tabs>

      <div
        hidden={imagePopUpConfig.hidden}
        className="article__image--zoom--in"
      >
        <img
          src={imagePopUpConfig.url}
          alt={"name"}
          className="article__image--zoom--in--img"
        />
      </div>
    </Modal>
  );
});

export default (props) => {
  const { visible } = props;

  if (!visible) {
    return null;
  }

  return (
    <ItemsEntity
      filter={{
        where: { id: get(props, "article.item.id") },
      }}
      {...props}
      View={Loader(View)}
      NoData={View}
      Loading={() => <Spin></Spin>}
    ></ItemsEntity>
  );
};
