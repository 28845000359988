import { useQuery } from "@apollo/react-hooks";
import { List, Skeleton, Spin } from "antd";
import { dequal } from "dequal";
import { flatten, groupBy, isEmpty, uniq, uniqBy } from "lodash";
import get from "lodash/get";
import isNil from "lodash/isNil";
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import handleViewport from "react-in-viewport";
import { CATALOG } from "../../../../../shared/graphql/queries/manufacturers";
import { Store } from "../../../../../shared/store";
import { format } from "../../../../../utils/helpers";
import Cross from "./Cross";
import Filter from "./Filter";
import Render from "./Home/Render";
const getType = (search) => {
  const { id, car, reference, type, category } = search;

  if (!isNil(reference) && type === "direct") {
    return "direct";
  }
  if (!isNil(reference) && type === "trade") {
    return "trade";
  }
  if (!isNil(category)) {
    return "items";
  }
  if (!isNil(id) && type === "catalog") {
    return "catalog";
  }
  if (!isNil(id) && type === "mdd") {
    return "mdd";
  }
  if (!isNil(car)) {
    return "car";
  }
  return "tecdoc";
};
const Block = (props) => {
  const { inViewport, forwardedRef } = props;
  // const color = inViewport ? "#217ac0" : "#ff9800";
  // const text = inViewport ? "In viewport" : "Not in viewport";
  return (
    <div className="viewport-block" ref={forwardedRef}>
      {/* <h3>{text}</h3>
      <div style={{ width: "400px", height: "300px", background: color }} /> */}
    </div>
  );
};
const View = (props) => {
  const ViewportBlock = handleViewport(Block /** options: {}, config: {} **/);
  const [take, setTake] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  //const [0, set0] = useState(0);

  const [articles, setArticles] = useState([]);

  const { search } = props;

  const getGAEventReferenceFromSearch = (reference) => {
    return {
      category: "recherche",
      action: "reference",
      label: format(reference).toUpperCase(), // optional
      value: 99, // optional, must be a number
      //  nonInteraction: true, // optional, true/false
      //  transport: "xhr", // optional, beacon/xhr/image
    };
  };

  const getGAEventSupplierFromSearch = (search) => {
    console.log(
      "send event",
      `${
        search.assemblyGroupBean.name + " (" + search.brandBean.name + ")"
      }`.toUpperCase()
    );

    return {
      category: "recherche",
      action: "supplierAssembly",
      label: `${
        search.assemblyGroupBean.name + " (" + search.brandBean.name + ")"
      }`.toUpperCase(), // optional
      value: 99, // optional, must be a number
      //  nonInteraction: true, // optional, true/false
      //  transport: "xhr", // optional, beacon/xhr/image
    };
  };

  const getGAEventCarFromSearch = (search) => {
    console.log("send event", {
      category: "recherche",
      action: "car",
      label: `${
        search.assemblyGroup.name +
        " (" +
        search.manufacturerBean.name +
        " -> " +
        search.modelBean.name +
        " -> " +
        search.motorizationBean.name +
        ")"
      }`.toUpperCase(), // optional
      value: 99, // optional, must be a number
      //  nonInteraction: true, // optional, true/false
      //  transport: "xhr", // optional, beacon/xhr/image
    });

    return {
      category: "recherche",
      action: "car",
      label: `${
        search.assemblyGroup.name +
        " (" +
        search.manufacturerBean.name +
        " -> " +
        search.modelBean.name +
        " -> " +
        search.motorizationBean.name +
        ")"
      }`.toUpperCase(), // optional
      value: 99, // optional, must be a number
      //  nonInteraction: true, // optional, true/false
      //  transport: "xhr", // optional, beacon/xhr/image
    };
  };

  const {
    oems,
    assemblyGroups,
    nouveauTopVente,
    nouveauTopVenteDataSource,
    setNouveauTopVenteDataSource,
    setCriteresDataSource,
    criteresDataSource,
    criteres,
    setVisible,
    setSelectedArticle,
    setAjouterPerteVenteVisible,
  } = props;
  const { data, loading, error } = useQuery(CATALOG, {
    variables: {
      where: { searchType: getType(search), search },
    },
    onCompleted: (data) => {
      // console.log("send event", search);
      if (["direct", "mdd", "catalog"].includes(search.type)) {
        ReactGA.event(
          getGAEventReferenceFromSearch(
            search.type === "direct"
              ? search.reference
              : search.type === "mdd"
              ? search.id
              : search.code
          )
        );
      } else if (search.type === "supplierAssembly") {
        ReactGA.event(getGAEventSupplierFromSearch(search));
      } else if (getType(search) === "car") {
        ReactGA.event(getGAEventCarFromSearch(search));
      }
    },
  });
  useEffect(() => {
    //  set0(0);
    setIsLoading(true);
    const {
      firsts = [],
      inStock = [],
      outOfStock = [],
      indispo = [],
    } = get(data, "catalog.data.articles.data", {});

    /////////////

    const articles = (ours) => {
      const condition = (value) => (ours ? !isEmpty(value) : isEmpty(value));
      return Object.entries(
        groupBy(
          [...firsts, ...inStock, ...outOfStock, ...indispo],
          (item) => item.brand.name
        )
      )
        .filter(
          ([key, values]) =>
            condition(
              values.filter(({ item: { id } }) => {
                return id !== -1;
              })
            ) &&
            !isEmpty(
              values.filter(({ assemblyGroups: assGroup }) => {
                const checkAssemmbly = isEmpty(assemblyGroups)
                  ? true
                  : assemblyGroups.reduce((prev, curr) => {
                      if (assGroup.map(({ name }) => name).includes(curr)) {
                        prev = prev + 1;
                      }
                      return prev;
                    }, 0) > 0;

                return checkAssemmbly;
              })
            )
        )
        .map(([key, values]) => ({
          id: key,
          name: key,
        }));
    };

    ////////////////////

    const assemblyGroupsTemp = uniqBy(
      flatten(
        [...firsts, ...inStock, ...outOfStock, ...indispo]
          .filter(({ brand: { name } }) => {
            return isEmpty(oems) ? true : oems.includes(name);
          })
          .map(({ assemblyGroups }) => assemblyGroups)
      ),
      "name"
    );

    var assemblyGroupsTemp2 = [];

    for (var i = 0; i < assemblyGroupsTemp.length; i++) {
      if (
        [...firsts, ...inStock, ...outOfStock, ...indispo].filter(
          // eslint-disable-next-line no-loop-func
          (article) =>
            article.assemblyGroups.length > 0 &&
            article.assemblyGroups[0].name === assemblyGroupsTemp[i].name &&
            article.item.id !== -1
        ).length > 0
      )
        assemblyGroupsTemp2.push({
          id: assemblyGroupsTemp[i].id,
          name: assemblyGroupsTemp[i].name,
          local: "our",
        });
      else
        assemblyGroupsTemp2.push({
          id: assemblyGroupsTemp[i].id,
          name: assemblyGroupsTemp[i].name,
          local: "other",
        });
    }

    props.setAssemblyGroupsList(assemblyGroupsTemp2);

    props.setBrandOthers(articles(false));
    props.setBrandOurs(articles(true));

    var nouveauTopVenteDataSourceCopy = [
      { key: "topVente", value: "Top vente", count: 0 },
      { key: "nouveau", value: "Nouveau", count: 0 },
    ];

    var criteresDataSourceNouveau = uniqBy(
      flatten(
        flatten([...firsts, ...inStock, ...outOfStock, ...indispo]).map(
          ({ attributes }) => {
            return attributes.map(({ id, name }) => {
              return {
                id,
                name,
              };
            });
          }
        )
      ),
      "id"
    );

    criteresDataSourceNouveau = criteresDataSourceNouveau.map((critere) => {
      return {
        ...critere,
        values: uniq(
          flatten(
            flatten([...firsts, ...inStock, ...outOfStock, ...indispo]).map(
              ({ attributes }) => {
                return attributes
                  .filter((attribute) => attribute.id === critere.id)
                  .map(({ value }) => {
                    return value;
                  });
              }
            )
          )
        ),
      };
    });

    setCriteresDataSource(criteresDataSourceNouveau);

    console.log("criteresDataSourceNouveau", criteresDataSourceNouveau);

    var articlesCopy = {
      firsts: firsts.filter(
        ({
          brand: { name },
          assemblyGroups: assGroup,
          attributes,
          item: { topVente, nouveau },
        }) => {
          const checkOems = isEmpty(oems) ? true : oems.includes(name);
          const checkAssemmbly = isEmpty(assemblyGroups)
            ? true
            : assemblyGroups.reduce((prev, curr) => {
                if (assGroup.map(({ name }) => name).includes(curr)) {
                  prev = prev + 1;
                }
                return prev;
              }, 0) > 0;

          nouveauTopVenteDataSourceCopy = [
            {
              key: "topVente",
              value: "Top vente",
              count:
                nouveauTopVenteDataSourceCopy.filter(
                  (item) => item.key === "topVente"
                )[0].count + (topVente === "Y" ? 1 : 0),
            },
            {
              key: "nouveau",
              value: "Nouveau",
              count:
                nouveauTopVenteDataSourceCopy.filter(
                  (item) => item.key === "nouveau"
                )[0].count + (nouveau === "Y" ? 1 : 0),
            },
          ];

          const checkNouveauTopVente = isEmpty(nouveauTopVente)
            ? true
            : nouveauTopVente.filter(
                (item) =>
                  (item.key === "nouveau" && nouveau === "Y") ||
                  (item.key === "topVente" && topVente === "Y")
              ).length > 0;

          const checkAttributes = isEmpty(criteres)
            ? true
            : attributes.filter(
                (attributeItem) =>
                  criteres.filter(
                    (critereItem) =>
                      critereItem.id.toString() ===
                        attributeItem.id.toString() &&
                      critereItem.value.toString() ===
                        attributeItem.value.toString()
                  ).length > 0
              ).length === criteres.length;

          return (
            checkOems &&
            checkAssemmbly &&
            checkNouveauTopVente &&
            checkAttributes
          );
        }
      ),

      inStock: inStock.filter(
        ({
          brand: { name },
          assemblyGroups: assGroup,
          attributes,
          item: { topVente, nouveau },
        }) => {
          const checkOems = isEmpty(oems) ? true : oems.includes(name);
          const checkAssemmbly = isEmpty(assemblyGroups)
            ? true
            : assemblyGroups.reduce((prev, curr) => {
                if (assGroup.map(({ name }) => name).includes(curr)) {
                  prev = prev + 1;
                }
                return prev;
              }, 0) > 0;

          nouveauTopVenteDataSourceCopy = [
            {
              key: "topVente",
              value: "Top vente",
              count:
                nouveauTopVenteDataSourceCopy.filter(
                  (item) => item.key === "topVente"
                )[0].count + (topVente === "Y" ? 1 : 0),
            },
            {
              key: "nouveau",
              value: "Nouveau",
              count:
                nouveauTopVenteDataSourceCopy.filter(
                  (item) => item.key === "nouveau"
                )[0].count + (nouveau === "Y" ? 1 : 0),
            },
          ];

          const checkNouveauTopVente = isEmpty(nouveauTopVente)
            ? true
            : nouveauTopVente.filter(
                (item) =>
                  (item.key === "nouveau" && nouveau === "Y") ||
                  (item.key === "topVente" && topVente === "Y")
              ).length > 0;

          const checkAttributes = isEmpty(criteres)
            ? true
            : attributes.filter(
                (attributeItem) =>
                  criteres.filter(
                    (critereItem) =>
                      critereItem.id.toString() ===
                        attributeItem.id.toString() &&
                      critereItem.value.toString() ===
                        attributeItem.value.toString()
                  ).length > 0
              ).length === criteres.length;

          return (
            checkOems &&
            checkAssemmbly &&
            checkNouveauTopVente &&
            checkAttributes
          );
        }
      ),
      outOfStock: outOfStock.filter(
        ({
          brand: { name },
          assemblyGroups: assGroup,
          attributes,
          item: { topVente, nouveau },
        }) => {
          const checkOems = isEmpty(oems) ? true : oems.includes(name);
          const checkAssemmbly = isEmpty(assemblyGroups)
            ? true
            : assemblyGroups.reduce((prev, curr) => {
                if (assGroup.map(({ name }) => name).includes(curr)) {
                  prev = prev + 1;
                }
                return prev;
              }, 0) > 0;

          nouveauTopVenteDataSourceCopy = [
            {
              key: "topVente",
              value: "Top vente",
              count:
                nouveauTopVenteDataSourceCopy.filter(
                  (item) => item.key === "topVente"
                )[0].count + (topVente === "Y" ? 1 : 0),
            },
            {
              key: "nouveau",
              value: "Nouveau",
              count:
                nouveauTopVenteDataSourceCopy.filter(
                  (item) => item.key === "nouveau"
                )[0].count + (nouveau === "Y" ? 1 : 0),
            },
          ];

          const checkNouveauTopVente = isEmpty(nouveauTopVente)
            ? true
            : nouveauTopVente.filter(
                (item) =>
                  (item.key === "nouveau" && nouveau === "Y") ||
                  (item.key === "topVente" && topVente === "Y")
              ).length > 0;

          const checkAttributes = isEmpty(criteres)
            ? true
            : attributes.filter(
                (attributeItem) =>
                  criteres.filter(
                    (critereItem) =>
                      critereItem.id.toString() ===
                        attributeItem.id.toString() &&
                      critereItem.value.toString() ===
                        attributeItem.value.toString()
                  ).length > 0
              ).length === criteres.length;

          return (
            checkOems &&
            checkAssemmbly &&
            checkNouveauTopVente &&
            checkAttributes
          );
        }
      ),
      indispo: indispo.filter(
        ({
          brand: { name },
          assemblyGroups: assGroup,
          attributes,
          item: { topVente, nouveau },
        }) => {
          const checkOems = isEmpty(oems) ? true : oems.includes(name);
          const checkAssemmbly = isEmpty(assemblyGroups)
            ? true
            : assemblyGroups.reduce((prev, curr) => {
                if (assGroup.map(({ name }) => name).includes(curr)) {
                  prev = prev + 1;
                }
                return prev;
              }, 0) > 0;

          nouveauTopVenteDataSourceCopy = [
            {
              key: "topVente",
              value: "Top vente",
              count:
                nouveauTopVenteDataSourceCopy.filter(
                  (item) => item.key === "topVente"
                )[0].count + (topVente === "Y" ? 1 : 0),
            },
            {
              key: "nouveau",
              value: "Nouveau",
              count:
                nouveauTopVenteDataSourceCopy.filter(
                  (item) => item.key === "nouveau"
                )[0].count + (nouveau === "Y" ? 1 : 0),
            },
          ];

          const checkNouveauTopVente = isEmpty(nouveauTopVente)
            ? true
            : nouveauTopVente.filter(
                (item) =>
                  (item.key === "nouveau" && nouveau === "Y") ||
                  (item.key === "topVente" && topVente === "Y")
              ).length > 0;

          const checkAttributes = isEmpty(criteres)
            ? true
            : attributes.filter(
                (attributeItem) =>
                  criteres.filter(
                    (critereItem) =>
                      critereItem.id.toString() ===
                        attributeItem.id.toString() &&
                      critereItem.value.toString() ===
                        attributeItem.value.toString()
                  ).length > 0
              ).length === criteres.length;

          return (
            checkOems &&
            checkAssemmbly &&
            checkNouveauTopVente &&
            checkAttributes
          );
        }
      ),
    };

    setArticles(articlesCopy);

    setNouveauTopVenteDataSource(nouveauTopVenteDataSourceCopy);
    setTake(10);
    setIsLoading(false);

    // eslint-disable-next-line
  }, [assemblyGroups, loading, nouveauTopVente, oems, props]);

  // const onChangePage = (page) => set0(page * 10 - 10);
  if (loading)
    return (
      <List
        dataSource={[0, 1, 3]}
        itemLayout="vertical"
        size="medium"
        renderItem={(item) => (
          <List.Item key={item}>
            <Skeleton
              active
              size={"medium"}
              SkeletonAvatarProps={{
                size: "medium",
              }}
            />
          </List.Item>
        )}
      />
    );

  const { firsts = [], inStock = [], outOfStock = [], indispo = [] } = articles;
  return (
    <Spin spinning={isLoading}>
      <Render
        setVisible={setVisible}
        setAjouterPerteVenteVisible={setAjouterPerteVenteVisible}
        setSelectedArticle={setSelectedArticle}
        articles={[
          ...firsts.sort((a, b) => {
            if (a.brand.name < b.brand.name) {
              return -1;
            }
            if (a.brand.name > b.brand.name) {
              return 1;
            }
            return 0;
          }),
          ...inStock.sort((a, b) => {
            if (a.brand.name < b.brand.name) {
              return -1;
            }
            if (a.brand.name > b.brand.name) {
              return 1;
            }
            return 0;
          }),
          ...outOfStock.sort((a, b) => {
            if (a.brand.name < b.brand.name) {
              return -1;
            }
            if (a.brand.name > b.brand.name) {
              return 1;
            }
            return 0;
          }),
          ...indispo.sort((a, b) => {
            if (a.brand.name < b.brand.name) {
              return -1;
            }
            if (a.brand.name > b.brand.name) {
              return 1;
            }
            return 0;
          }),
        ]
          .slice(0, take)
          .reduce(
            (acc, curr, index) => {
              if (index + 1 + 0 <= firsts.length) {
                acc["firsts"].push(curr);
                return acc;
              }
              if (index + 1 + 0 <= firsts.length + inStock.length) {
                acc["inStock"].push(curr);
                return acc;
              }
              if (
                index + 1 + 0 <=
                firsts.length + inStock.length + outOfStock.length
              ) {
                acc["outOfStock"].push(curr);
                return acc;
              }
              if (
                index + 1 + 0 <=
                firsts.length +
                  inStock.length +
                  outOfStock.length +
                  indispo.length
              ) {
                acc["indispo"].push(curr);
                return acc;
              }
            },
            {
              firsts: [],
              inStock: [],
              outOfStock: [],
              indispo: [],
            }
          )}
      ></Render>

      <ViewportBlock
        onEnterViewport={() => setTake(take + 10)}
        onLeaveViewport={() => {}}
      />
    </Spin>
  );
};

export default Store(
  Filter(
    Cross(
      React.memo(
        (props) => {
          return (
            <div
              className="col-lg-8 col-xl-9 order-sm-1 order-lg-2 "
              style={{ padding: "0px" }}
            >
              {/* <DataView
                oems={props.oems}
                assemblyGroups={props.assemblyGroups}
                {...props}
              ></DataView> */}
              <View {...props} />
            </div>
          );
        },
        (prev, next) => dequal(prev, next)
      )
    )
  )
);
