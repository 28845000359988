import React from "react";
import Attributes from "./Attributes";
import Information from "./Information";
import OENS from "./OENS";
import Pdf from "./Pdf";
import Substitute from "./Substitute";
import UsageNumbers from "./UsageNumbers";

export default React.memo(({ article, history }) => {
  return (
    <div className="modal_details">
      {/* <Row className="modal_details">
        <Row
          gutter={[24, 16]}
          className="div-horizontal"
          style={{ alignItems: "stretch" }}
        >
          <Col span={12} className="div-vertical">
            <Information article={article} history={history} />
          </Col>
          <Col span={12}>
            <Attributes article={article} />
          </Col>
        </Row>
      </Row> */}
      <div>
        <Information article={article} history={history} />
      </div>

      <div>
        <Attributes article={article} />
      </div>

      <div>
        <OENS article={article} />
      </div>
      <div>
        <UsageNumbers article={article} />
      </div>
      <div>
        <Substitute article={article} />
      </div>
      <div>
        <Pdf article={article} />
      </div>
    </div>
  );
});
