import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { AutoComplete, Input, Spin } from "antd";
import get from "lodash/get";
import React, { useContext, useState } from "react";
import { TabContext } from "../../../../Context/Tab";
import { ADD_RECHERCHE } from "../../../graphql/mutations/recherche";
import { SEARCH_BAR } from "../../../graphql/queries/manufacturers";
import fromatter from "./fromatter";
import "./style.scss";

const { Search } = Input;

const { OptGroup, Option } = AutoComplete;
const Keys = {
  articles: "Articles",

  cars: "Code moteur",

  ean: "EAN",

  items: "N° commercial",

  oe: "N° OE",

  tradeNumber: "Trade number",

  vehicles: "Vin",

  description: "Description",
};
const Screen = (props) => {
  const { dispatch } = useContext(TabContext);

  const [reference, setReference] = useState("");
  const [executeLazyQuery, { data, loading }] = useLazyQuery(SEARCH_BAR);
  const [timer, setTimer] = useState(() => {});

  const [take, setTake] = useState(12);

  const [
    addRecherche,
    { dataAddRecherche, loading: loadingAddRecherche, errorAddRecherche },
  ] = useMutation(ADD_RECHERCHE);
  return (
    <div className="global-search-placeholder" style={{ width: "100%" }}>
      <AutoComplete
        className="global-search"
        dropdownClassName="autoComplete"
        size="medium"
        style={{ width: "100%" }}
        dataSource={
          !reference
            ? []
            : Object.keys(fromatter).map((key) => {
                return loading ? (
                  <OptGroup key={Keys[key]} label={Keys[key]}>
                    <Option ref={"Loading"} key={"Loading"}>
                      <Spin></Spin>
                    </Option>
                  </OptGroup>
                ) : (
                  <OptGroup key={Keys[key]} label={Keys[key]}>
                    {get(get(data, "searchBar", {})[key], "data", [])
                      .slice(0, take)
                      .map(
                        fromatter[key](
                          dispatch,
                          props.onArticlClik,
                          reference,
                          addRecherche
                        )
                      )}
                  </OptGroup>
                );
              })
        }
        onChange={(_) => {
          setTake(12);
          setReference(_);
          clearTimeout(timer);
          // Sets new timer that may or may not get cleared
          _ &&
            setTimer(
              setTimeout(() => {
                executeLazyQuery({
                  variables: { where: { reference: _.toUpperCase() } },
                });
              }, 450)
            );

          // const upperCaseValue = _.toUpperCase();
          // return sources.reduce((accumulator, { title, getData, filter }) => {
          //   setReference(filter(upperCaseValue));
          //   setSearchValue(upperCaseValue);
          //   accumulator[title] = getData(filter(upperCaseValue));
          //   return accumulator;
          // }, {});
        }}
        optionLabelProp={"key"}
        onSelect={(_, __) => {
          // const ___ = find(sources, (source) => source.title === __.ref);
          // return ___ && ___.onSelect(_);
        }}
      >
        <Input.Search
          size="medium"
          placeholder="Entrez votre référence article, OE, EAN, VIN, Code moteur…"
          enterButton
        />
      </AutoComplete>
    </div>
  );
};

export default Screen;
