import { Icon, Layout, Menu } from "antd";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { PATHS } from "../../../utils/constants";

const { Sider } = Layout;
const { SubMenu, Item } = Menu;

const items = [
  /*   {
    title: "Stock",
    url: PATHS.ADMIN.STOCK.LIST,
    key: "stock",
    icon: "project",
    type: "simple",
  }, */
  {
    title: "Home",
    url: "/admin",
    key: "home",
    icon: "home",
    type: "simple",
  },
  {
    title: "Entrées",
    url: "/admin/entries",
    key: "entries",
    icon: "project",
    type: "simple",
  },
  {
    title: "En vedette",
    url: "/admin/vedettes",
    key: "vedette",
    icon: "project",
    type: "simple",
  },

  {
    title: "Commerciaux",
    url: "/admin/commercial",
    key: "commercial",
    icon: "project",
    type: "simple",
  },
  {
    title: "Zones",
    url: "/admin/zone",
    key: "zone",
    icon: "project",
    type: "simple",
  },
  {
    title: "Comm. régionaux",
    url: "/admin/commercial-regional",
    key: "commercial regional",
    icon: "project",
    type: "simple",
  },

  {
    title: "Nos concurrents",
    url: "/admin/concurrent",
    key: "concurrent",
    icon: "project",
    type: "simple",
  },
  {
    title: "Pertes des ventes",
    url: "/admin/perte-vente",
    key: "perte-vente",
    icon: "project",
    type: "simple",
  },

  {
    title: "Bannière",
    url: "/admin/banners",
    key: "banner",
    icon: "project",
    type: "simple",
  },
  {
    title: "MDD",
    url: "/admin/mdds",
    key: "mdd",
    icon: "project",
    type: "simple",
  },
  {
    title: "Notifications",
    url: "/admin/notifications",
    key: "notifications",
    icon: "project",
    type: "simple",
  },
  {
    title: "Synchronisation",
    url: "/admin/synchronisation",
    key: "synchronisation",
    icon: "project",
    type: "simple",
  },
  {
    title: "cron jobs",
    url: PATHS.ADMIN.CRON_JOB.LIST,
    key: "cronjob",
    icon: "project",
    type: "simple",
  },
  {
    title: "Utilisateurs",
    url: "/admin/users",
    key: "users",
    icon: "user",
    type: "simple",
  },
  {
    title: "Fournisseurs",
    url: "/admin/suppliers",
    key: "suppliers",
    icon: "project",
    type: "simple",
  },
  {
    title: "Promotions",
    url: "/admin/promotions",
    key: "promotions",
    icon: "project",
    type: "simple",
  },
  {
    title: "Rappels",
    url: "/admin/supports",
    key: "supports",
    icon: "project",
    type: "simple",
  },
  {
    title: "Factures",
    url: "/admin/invoices",
    key: "invoices",
    icon: "project",
    type: "simple",
  },
  {
    title: "Commandes",
    url: "/admin/orders",
    key: "orders",
    icon: "project",
    type: "simple",
  },
  {
    title: "Avoirs",
    url: "/admin/assets",
    key: "assets",
    icon: "project",
    type: "simple",
  },
  {
    title: "Panier",
    url: "/admin/cart-items",
    key: "cart-items",
    icon: "project",
    type: "simple",
  },
  {
    title: "Liste des souhaits",
    url: "/admin/wish-list-items",
    key: "wish-list-items",
    icon: "project",
    type: "simple",
  },
  {
    title: "Accés",
    url: "/admin/accesses",
    key: "access",
    icon: "project",
    type: "simple",
  },
  {
    title: "Réclamations",
    url: "/admin/claims",
    key: "claims",
    icon: "project",
    type: "simple",
  },
  {
    title: "Devis",
    url: "/admin/quotes",
    key: "quotes",
    icon: "project",
    type: "simple",
  },
  {
    title: "Uploads",
    url: "/admin/uploads",
    key: "uploads",
    icon: "project",
    type: "simple",
  },
  {
    title: "AssemblyGroups",
    url: "/admin/assemblyGroups",
    key: "assemblyGroups",
    icon: "project",
    type: "simple",
  },
];

class Side extends Component {
  state = {
    items: items,
    collapsed: false,
  };

  onCollapse = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  render() {
    const { items } = this.state;
    return (
      <Sider
        collapsible
        collapsed={this.state.collapsed}
        onCollapse={this.onCollapse}
        theme="light"
        className="sidebar--admin"
      >
        <Menu theme="light" mode="inline" selectable={false}>
          {items.map(({ url, title, key, icon, type, children }) => {
            const Widget =
              type === "simple" ? (
                <Item key={key}>
                  <Link to={url}>
                    <Icon type={icon} />
                    <span>{title}</span>
                  </Link>
                </Item>
              ) : (
                <SubMenu
                  key={key}
                  title={
                    <span>
                      <Icon type="mail" />
                      <Link to={url} style={{ textDecoration: "none" }}>
                        <span>{title}</span>
                      </Link>
                    </span>
                  }
                >
                  {children.map((child) => (
                    <Item key={child.key}>
                      <Link to={child.url}>
                        <Icon type={child.icon} />
                        <span>{child.title}</span>
                      </Link>
                    </Item>
                  ))}
                </SubMenu>
              );
            return Widget;
          })}
        </Menu>
      </Sider>
    );
  }
}

export default Side;
