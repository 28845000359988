import { Select, Tooltip } from "antd";
import { uniqBy } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { Query } from "react-apollo";

const { Option } = Select;

const QuerySelect = ({
  query,
  variables,
  placeholder,
  onChange,
  accessorFn,
}) => {
  return (
    <Query query={query} variables={variables}>
      {({ loading, error, data }) => {
        if (error) return `Error! ${error.message}`;

        const children = uniqBy(accessorFn(data), "id");
        return (
          <Select
            style={{ width: "100%" }}
            showSearch
            size="medium"
            placeholder={placeholder}
            onChange={(e) => {
              onChange(children.filter((item) => item.id === e)[0]);
            }}
            filterOption={(input, option) => {
              return (
                option.props.children.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              );
            }}
            loading={loading}
          >
            {children.map((child) => (
              <Option key={child.id}>
                <Tooltip placement="top" title={child.name}>
                  {child.name}
                </Tooltip>
              </Option>
            ))}
          </Select>
        );
      }}
    </Query>
  );
};

QuerySelect.propTypes = {
  query: PropTypes.object.isRequired,
  variables: PropTypes.object.isRequired,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  accessorFn: PropTypes.func.isRequired,
};

export default QuerySelect;
