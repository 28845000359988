import { Icon } from "antd";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import uniq from "lodash/uniq";
import React, { useContext } from "react";
import DirectArticle from "../../../../../../../entities/direct-article";
import Loader from "../../../../../../../shared/components/Loader";
import { SCREENS, TABS_ACTIONS } from "../../../../../../../utils/constants";
import { TabContext } from "../../../../.././../../Context/Tab";

const Equivalence = ({
  hasEquivalence,
  onPress,
  style,
  disabled,
  referenceArticle,
  code,
}) => {
  if (!hasEquivalence) return null;
  return (
    <div
      className="div-horizontal center"
      onClick={!disabled ? onPress : null}
      style={{ marginRight: 5, ...style, cursor: "pointer" }}
    >
      <Icon
        style={{ fontSize: "20px", color: disabled && "#d2d2d2" }}
        type="setting"
      />
    </div>
  );
};

export default ({ oens, code, referenceArticle, itemId }) => {
  const { dispatch } = useContext(TabContext);
  if (oens)
    return (
      <Equivalence
        code={code}
        referenceArticle={referenceArticle}
        hasEquivalence={true}
        onPress={() =>
          dispatch({
            type: TABS_ACTIONS.HISTORY_PUSH,
            payload: {
              screen: SCREENS.ARTICLES,
              props: {
                search: {
                  id: referenceArticle,
                  code,
                  item: {
                    catalogCode: code,
                    ...(itemId !== -1 && { id: itemId }),
                  },
                  oens: uniq(oens.map(({ oenNumber }) => oenNumber)),
                },
              },
            },
          })
        }
        disabled={isEmpty(oens)}
      />
    );

  return (
    <DirectArticle
      NoData={() => null}
      filter={{
        where: { type: "REFERENCE", reference: code },
        like: {},
      }}
      options={{ fetchPolicy: "cache-first" }}
      View={Loader(({ data }) => {
        const article = data.find(
          ({ code: codeArticle }) => code === codeArticle
        );

        return (
          <Equivalence
            code={code}
            hasEquivalence={true}
            onPress={() =>
              dispatch({
                type: TABS_ACTIONS.HISTORY_PUSH,
                payload: {
                  screen: SCREENS.ARTICLES,
                  props: {
                    search: {
                      id: article.id,
                      code,
                      item: {
                        catalogCode: code,
                        ...(itemId !== -1 && { id: itemId }),
                      },
                      oens: uniq(
                        (get(article, "oens") || []).map(
                          ({ oenNumber }) => oenNumber
                        )
                      ),
                    },
                  },
                },
              })
            }
            disabled={isEmpty(get(article, "oens") || [])}
          />
        );
      })}
    ></DirectArticle>
  );
};
