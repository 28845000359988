import { Spin } from "antd";
import get from "lodash/get";
import React, { useContext } from "react";
import { TabContext } from "../../Context/Tab";
import Vehicles from "../../entities/vehicles";
import Loader from "../../shared/components/Loader";
import { SCREENS, TABS_ACTIONS } from "../../utils/constants";
import Path from "./Path";

const View = ({ car, assemblyGroup, name, data }) => {
  //  console.log("car motorization", { car, assemblyGroup, name, data });
  const { dispatch } = useContext(TabContext);

  const path = [
    {
      onPress: () =>
        dispatch({
          type: TABS_ACTIONS.HISTORY_PUSH,
          payload: {
            screen: SCREENS.MODELS,
            props: {
              search: {
                manufacturer: get(data[0], "manufacturer", {}).id,
                manufacturerBean: get(data[0], "manufacturer", {}),
              },
            },
          },
        }),
      text: get(data[0], "manufacturer", {}).name,
    },
    {
      onPress: () =>
        dispatch({
          type: TABS_ACTIONS.HISTORY_PUSH,
          payload: {
            screen: SCREENS.VEHICLES,
            props: {
              search: {
                manufacturer: get(data[0], "manufacturer", {}).id,
                model: get(data[0], "model", {}).id,

                manufacturerBean: get(data[0], "manufacturer", {}),
                modelBean: get(data[0], "model", {}),
              },
            },
          },
        }),
      text: get(data[0], "model", {}).name,
    },
    {
      onPress: () => {
        console.log("dispatch 13 *******");

        return dispatch({
          type: TABS_ACTIONS.HISTORY_PUSH,
          payload: {
            screen: SCREENS.ASSEMBLYGROUPS,
            props: {
              search: {
                manufacturer: get(data[0], "manufacturer", {}).id,
                linkingTarget: car.id,
                model: get(data[0], "model", {}).id,

                manufacturerBean: get(data[0], "manufacturer", {}),
                motorizationBean: car,
                modelBean: get(data[0], "model", {}),
              },
            },
          },
        });
      },
      text: data[0].name,
    },
    {
      onPress: () => {
        return dispatch({
          type: TABS_ACTIONS.HISTORY_PUSH,
          payload: {
            screen: SCREENS.ARTICLES,
            props: {
              search: {
                name,
                assemblyGroup,
                car: { id: car.id },
                manufacturer: get(data[0], "manufacturer", {}).id,
                model: get(data[0], "model", {}).id,

                manufacturerBean: get(data[0], "manufacturer", {}),
                motorizationBean: car,
                modelBean: get(data[0], "model", {}),
              },
            },
          },
        });
      },
      text: assemblyGroup.name,
    },
  ];
  return <Path path={path}></Path>;
};

export default ({ car, name, assemblyGroup }) => {
  console.log("where cars", { car, name, assemblyGroup });
  const variables = {
    where: { cars: [{ id: car.id }] },
  };

  return (
    <Vehicles
      assemblyGroup={assemblyGroup}
      filter={variables}
      NoData={() => null}
      View={Loader(View)}
      car={car}
      name={name}
      Loading={() => <Spin size="medium" />}
    ></Vehicles>
  );
};
