import { Select } from "antd";
import Joi from "joi";
import isNil from "lodash/isNil";
import React, { Component } from "react";

const validations = {
  manufacturers: Joi.object({
    where: Joi.object().required(),
  }),
  models: Joi.object({
    where: Joi.object({
      manufacturer: Joi.object({
        id: Joi.string().alphanum().min(1).max(30).required(),
      }).required(),
    }).required(),
  }),
  vehicles: Joi.object({
    where: Joi.object({
      manufacturer: Joi.object({
        id: Joi.string().alphanum().min(1).max(30).required(),
      }).required(),
      model: Joi.object({
        id: Joi.string().alphanum().min(1).max(30).required(),
      }).required(),
    }).required(),
  }),
};

const WithValidation = (WrappedComponent) => {
  return class WithValidation extends Component {
    render() {
      const { accessor } = this.props;
      const { error } = validations[[accessor]].validate(this.props.variables);
      if (!isNil(error))
        return (
          <Select
            style={{ width: "100%" }}
            size="medium"
            showSearch
            placeholder={this.props.placeholder}
          >
            {[]}
          </Select>
        );

      return <WrappedComponent {...this.props} />;
    }
  };
};

export default WithValidation;
