import { Input, Spin, Table } from "antd";
import React, { useContext, useState } from "react";
import { Query } from "react-apollo";
import { TabContext } from "../../../../../Context/Tab";
import Breadcrumb from "../../../../../shared/components/Breadcrumb";
import { ManufacturersQueries } from "../../../../../shared/graphql/queries";
import { SCREENS, TABS_ACTIONS } from "../../../../../utils/constants";

const { ASSEMBLY_GROUPS } = ManufacturersQueries;

const columns = [
  {
    title: "#id",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
];

export default (props) => {
  const { search } = props;
  const { dispatch } = useContext(TabContext);

  const [filter, setFilter] = useState("");
  const {
    linkingTarget,
    manufacturer,
    model,
    manufacturerBean,
    modelBean,
    motorizationBean,
  } = search;

  const variables = {
    where: {
      linkingTarget: { id: linkingTarget },
      linked: true,
    },
  };

  return (
    <div>
      <Query query={ASSEMBLY_GROUPS} variables={variables}>
        {({ loading, error, data }) => {
          if (loading) return <Spin></Spin>;
          if (error) return `Error! ${error.message}`;
          const { data: assemblyGroups } = data.assemblyGroups;
          return (
            <div className="section full-height">
              <div className="titre-div-horizontal">
                <Breadcrumb location={{ search }} />

                <Input
                  size={"medium"}
                  placeholder="Cherchez une pièce"
                  allowClear
                  value={filter}
                  onChange={(_) => setFilter(_.target.value)}
                />
              </div>
              <div>
                <Table
                  bordered
                  showHeader={false}
                  rowKey={(record) => record.id}
                  columns={columns}
                  dataSource={assemblyGroups.filter((item) =>
                    item.name.toLowerCase().includes(filter.toLowerCase())
                  )}
                  onRow={({ id: assemblyGroupId, name }) => {
                    return {
                      onClick: () => {
                        dispatch({
                          type: TABS_ACTIONS.HISTORY_PUSH,
                          payload: {
                            screen: SCREENS.ARTICLES,
                            props: {
                              search: {
                                assemblyGroup: { id: assemblyGroupId, name },
                                car: { id: linkingTarget },
                                manufacturer,
                                model,
                                manufacturerBean,
                                modelBean,
                                motorizationBean,
                              },
                            },
                          },
                        });
                      },
                    };
                  }}
                />
              </div>
            </div>
          );
        }}
      </Query>
    </div>
  );
};
