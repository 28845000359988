import gql from "graphql-tag";

export const ADD_COMMERCIAL_REGIONAL = gql`
  mutation addCommercialRegional(
    $addCommercialRegionalInput: AddCommercialRegionalInput
  ) {
    addCommercialRegional(
      addCommercialRegionalInput: $addCommercialRegionalInput
    ) {
      message
    }
  }
`;

export const UPDATE_COMMERCIAL_REGIONAL = gql`
  mutation updateCommercialRegional(
    $updateCommercialRegionalInput: UpdateCommercialRegionalInput
  ) {
    updateCommercialRegional(
      updateCommercialRegionalInput: $updateCommercialRegionalInput
    ) {
      message
    }
  }
`;

export const DELETE_COMMERCIAL_REGIONAL = gql`
  mutation deleteCommercialRegional(
    $deleteCommercialRegionalInput: DeleteCommercialRegionalInput
  ) {
    deleteCommercialRegional(
      deleteCommercialRegionalInput: $deleteCommercialRegionalInput
    ) {
      message
    }
  }
`;

export default {
  ADD_COMMERCIAL_REGIONAL,
  UPDATE_COMMERCIAL_REGIONAL,
};
