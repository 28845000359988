import { Button, Select } from "antd";
import { isEmpty, uniqBy } from "lodash";
import isNil from "lodash/isNil";
import moment from "moment";
import React, { useContext, useState } from "react";
import { TabContext } from "../../../Context/Tab";
import ManufacturersQuery from "../../../entities/manufacturers";
import ModelsQuery from "../../../entities/models";
import VehiclesQuery from "../../../entities/vehicles";
import Loader from "../../../shared/components/Loader";
import { SCREENS, TABS_ACTIONS } from "../../../utils/constants";
import WithValidation from "./WithValidation";
const { Option } = Select;

export default ({ assemblyGroupId, setVisible, assemblyGroupName }) => {
  const { dispatch } = useContext(TabContext);
  const [manufacturerId, setManufacturerId] = useState(null);
  const [manufacturer, setManufacturer] = useState(null);

  const [modelId, setModelId] = useState(null);
  const [motorizationId, setMotorizationId] = useState(null);

  const [model, setModel] = useState(null);
  const [motorization, setMotorization] = useState(null);

  const setManufacturerParent = (manufacturer) => {
    setManufacturer(manufacturer);
    setManufacturerId(manufacturer.id);
  };

  const setModelParent = (model) => {
    //
    setModel(model);
    setModelId(model.id);
  };

  const setMotorizationParent = (motorization) => {
    //
    setMotorization(motorization);
    setMotorizationId(motorization.id);
  };

  const manufactureVariables = { where: {} };
  const modelVariables = isNil(manufacturerId)
    ? { where: {} }
    : { where: { manufacturer: { id: manufacturerId } } };
  const motorizationVariables =
    isNil(manufacturerId) || isNil(modelId)
      ? { where: {} }
      : {
          where: {
            manufacturer: { id: manufacturerId },
            model: { id: modelId },
          },
        };

  const button = isNil(motorizationId)
    ? {
        disabled: true,
        title: "Merci de choisir",
      }
    : {
        disabled: false,
        title: "Valider",
      };
  return (
    <div>
      <div
        style={{
          flexDirection: "row",
          flex: 1,
          alignContent: "center",
          justifyContent: "center",
        }}
      >
        {[
          {
            id: 1,
            Query: ManufacturersQuery,
            filter: manufactureVariables,
            placeholder: "Choisir la marque",
            onChange: setManufacturerParent,
            value: manufacturerId,
            accessor: "manufacturers",
            accessorFn: (data) => data,
          },
          {
            id: 2,
            Query: ModelsQuery,
            filter: modelVariables,
            placeholder: "Choisir le modèle",
            onChange: setModelParent,
            value: modelId,
            accessor: "models",
            accessorFn: (data) => {
              return data.map((model) => ({
                id: model.id,
                name: `${model.name}/ ${moment(
                  model.yearOfConstructionFrom,
                  "YYYYMM"
                ).format("YYYY")} 
                      -
                      ${moment(model.yearOfConstructionTo, "YYYYMM").format(
                        "YYYY"
                      )}`,
              }));
            },
          },
          {
            id: 3,
            Query: VehiclesQuery,
            filter: motorizationVariables,
            placeholder: "Choisir la motorisation",
            onChange: setMotorizationParent,
            value: motorizationId,
            accessor: "vehicles",
            accessorFn: (data) => {
              return data.map((vehicle) => ({
                id: vehicle.id,
                name: `${vehicle.name}/${vehicle.powerHorsePowerFrom}-${
                  vehicle.powerHorsePowerTo
                }cv, ${vehicle.motorType}, moteurs: [${vehicle.motors.join(
                  ","
                )}]`,
              }));
            },
          },
        ].map((props) => {
          const { Query } = props;
          const ProtectedQuerySelect = WithValidation(Query);

          return (
            <div style={{ marginTop: 15 }} key={`query-select-${props.id}`}>
              <ProtectedQuerySelect
                {...props}
                NoData={() => (
                  <Select
                    style={{ width: "100%" }}
                    showSearch
                    size="medium"
                    placeholder={props.placeholder}
                  >
                    {[]}
                  </Select>
                )}
                Loading={() => (
                  <Select
                    style={{ width: "100%" }}
                    showSearch
                    size="medium"
                    loading={true}
                    placeholder={props.placeholder}
                  >
                    {[]}
                  </Select>
                )}
                View={Loader(
                  ({ placeholder, onChange, accessorFn, data, value }) => {
                    const children = uniqBy(accessorFn(data), "id");
                    return (
                      <Select
                        style={{ width: "100%" }}
                        showSearch
                        size="medium"
                        placeholder={
                          value &&
                          !isEmpty(
                            children.filter((child) => child.id === value)
                          )
                            ? children.filter((child) => child.id === value)[0]
                                .name
                            : placeholder
                        }
                        onChange={(e) => {
                          onChange(
                            children.filter((child) => child.id === e)[0]
                          );
                        }}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {children.map((child) => (
                          <Option key={child.id}>{child.name}</Option>
                        ))}
                      </Select>
                    );
                  }
                )}
              />
            </div>
          );
        })}
      </div>

      <Button
        disabled={button.disabled}
        className="button-form-full"
        type="primary"
        size={"medium"}
        onClick={() => {
          dispatch({
            type: TABS_ACTIONS.HISTORY_PUSH,
            payload: {
              screen: SCREENS.ARTICLES,
              props: {
                search: {
                  car: { id: motorizationId },
                  assemblyGroup: {
                    id: assemblyGroupId,
                    name: assemblyGroupName,
                  },
                  name: assemblyGroupName,
                  manufacturer: manufacturerId,
                  model: modelId,

                  motorizationBean: motorization,
                  manufacturerBean: manufacturer,
                  modelBean: model,
                },
              },
            },
          });

          setVisible(false);
        }}
      >
        {button.title}
      </Button>
    </div>
  );
};
