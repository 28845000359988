import { useMutation } from "@apollo/react-hooks";
import { Icon, Input } from "antd";
import cuid from "cuid";
import { isEmpty } from "lodash";
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import {
  CartItemMutations,
  ItemMutations,
} from "../../../../../../shared/graphql/mutations";
import { CartItemsQueries } from "../../../../../../shared/graphql/queries";
import { StoreContext } from "../../../../../../shared/store";
import { DEFAULT_PAGE_SIZE_CART } from "../../../../../../utils/constants";

const { CREATE_ITEM } = ItemMutations;

const { CREATE_CART_ITEM } = CartItemMutations;
const { CART_ITEMS } = CartItemsQueries;
export default ({
  code,
  itemId,
  itemName,
  prices,
  itemWareHouses = [],
  openNotification,
  clientCity,
  quantity,
  setQuantity,
}) => {
  const { user = {} } = useContext(StoreContext);

  const history = useHistory();
  const [addItem] = useMutation(CREATE_ITEM, {
    onCompleted: ({ createItem: { id } }) =>
      addCartItem({
        variables: {
          data: {
            item: {
              id,
            },
            accountDestinationId: user.account.id,
            quantity,
            id: cuid(),
          },
        },
      }),
  });
  const handleCartPress = () => {
    itemId === -1 || isEmpty(itemId)
      ? addItem({
          variables: {
            data: {
              id: code,
              code,
              name: itemName,
            },
          },
        })
      : addCartItem({
          variables: {
            data: {
              item: {
                id: itemId,
              },
              accountDestinationId: user.account.id,

              quantity,
              id: cuid(),
            },
          },
        });
  };

  const addCartItemMutation = useMutation(CREATE_CART_ITEM, {
    update: (cache, { data: { createCartItem } }) => {
      const {
        cartItems,
        cartItems: { data, count },
      } = cache.readQuery({
        query: CART_ITEMS,
        variables: {
          where: { deleted: false },
          take: DEFAULT_PAGE_SIZE_CART,
        },
      });
      cache.writeQuery({
        query: CART_ITEMS,
        variables: {
          where: { deleted: false },
          take: DEFAULT_PAGE_SIZE_CART,
        },
        data: {
          cartItems: {
            ...cartItems,
            data: [createCartItem, ...data],
            count: count + 1,
          },
        },
      });
    },
    onCompleted: () => {
      if (openNotification) {
        openNotification("success");
        history.push("/cart-items");
      }
    },
  });
  const [addCartItem] = addCartItemMutation;
  const wareHouses = itemWareHouses.filter((item) => item.quantity > 0);

  return (
    <div className="div-horizontal center">
      <div style={{ marginRight: 10, width: 70 }}>
        <Input
          size="medium"
          value={quantity}
          type="number"
          min="1"
          onChange={(e) => setQuantity(parseInt(e.target.value))}
        />
      </div>
      <div>
        <Icon
          className="iconTable2"
          style={{ fontSize: "15px" }}
          type={addCartItemMutation.loading ? "loading" : "shopping-cart"}
          onClick={handleCartPress}
        />
      </div>
    </div>
  );
};
