import { Icon, Spin, Tooltip } from "antd";
import { get } from "lodash";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import { PanierContext } from "../../../../../../../../../Context/Panier";
import ItemsEntity from "../../../../../../../../../entities/item";
import BestSellerIcon from "../../../../../../../../../shared/assets/images/best_seller.png";
import NewIcon from "../../../../../../../../../shared/assets/images/new_icon.png";

import Attributes from "../../Attribute";
import Image from "../../Image";
import { Card, CardLoader } from "../Normal/NormalCard";
const Normal = ({
  referenceArticle,
  code,
  photo,
  photoVersion,
  photo2,
  photoVersion2,
  name,
  assemblyGroup,
  brandName,
  itemId,
  itemCode,
  itemWareHouses = [],
  oens,
  thumbnails = [],
  substitutes,
  attributes,
  account,
  detailLink,
  showAjouterPerteVente,
  detailImage,
  quantity,
  setQuantity,
  handleCartPress,
  addToWishList,
  cartItemLoading,
  itemLoading,
  setShowList,
  showList,
  checkIfMdds,
  topVente,
  nouveau,
  dateArrivage,
  application1,
  application2,
  application3,
  article,
  setArticleAvecPrix,
  articleAvecPrix,
}) => {
  const { articlesPourPanier, setArticlesPourPanier } =
    useContext(PanierContext);

  const setQuantityParent = (qte) => {
    const index = articlesPourPanier.findIndex(
      (articleItem) =>
        articleItem.panier.item.referenceArticle === article.referenceArticle
    );

    if (index > -1) {
      const articlesPourPanierCopy = [...articlesPourPanier];

      articlesPourPanierCopy[index].panier.quantity = qte;
      articlesPourPanierCopy[index].comparer.quantity = qte;

      setArticlesPourPanier(articlesPourPanierCopy);
    }

    setQuantity(qte);
  };

  const toggleArticlePourPanier = () => {
    if (
      articlesPourPanier.filter(
        (articleItem) =>
          articleItem.panier.item.referenceArticle ===
            article.referenceArticle &&
          articleItem.panier.item.itemId === article.item.id
      ).length > 0
    ) {
      setArticlesPourPanier(
        articlesPourPanier.filter(
          (articleItem) =>
            articleItem.panier.item.referenceArticle !==
              article.referenceArticle ||
            articleItem.panier.item.itemId !== article.item.id
        )
      );
    } else {
      setArticlesPourPanier([
        ...articlesPourPanier,

        {
          panier: {
            item: {
              referenceArticle: article.referenceArticle,
              itemId: itemId,
              id: itemId === -1 ? code : itemId,
              thumbnail: get(thumbnails, "[0].url", ""),
            },
            quantity,
          },
          comparer: articleAvecPrix,
        },
      ]);
      // console.log("articlesPourPanier", articlesPourPanier);
    }
  };

  return (
    <div className={"card_article"}>
      <div className={"div-checkbox " + (itemId !== -1 && "gere")}>
        <input
          style={{ width: 20, height: 20 }}
          type="checkbox"
          onChange={() => {}}
          checked={
            articlesPourPanier.findIndex(
              (articleItem) =>
                articleItem.panier.item.referenceArticle ===
                  article.referenceArticle &&
                articleItem.panier.item.itemId === itemId
            ) > -1
          }
          onClick={toggleArticlePourPanier}
        />
      </div>
      <div className="div-vertical" style={{ flexGrow: 1 }}>
        <div
          className="div-horizontal"
          style={{ width: "100%", justifyContent: "stretch" }}
        >
          <div className="div-vertical" style={{ position: "relative" }}>
            <div
              className="card_article_carousel text-center"
              onClick={detailImage}
            >
              <Image
                code={code}
                photo={photo}
                photoVersion={photoVersion}
                photo2={photo2}
                photoVersion2={photoVersion2}
                itemId={itemId}
                brand={brandName}
                quantity={quantity}
                thumbnails={thumbnails}
                itemWareHouses={itemWareHouses}
                size={"normal"}
                oem={brandName}
                checkIfMdds={checkIfMdds}
              ></Image>
            </div>
            {(topVente === "Y" || nouveau === "Y") && (
              <>
                <div className="ribbon down">
                  <Tooltip
                    placement="topLeft"
                    title={
                      nouveau === "Y" && topVente === "Y"
                        ? "Nouveau & Top vente"
                        : topVente === "Y"
                        ? "Top vente"
                        : "Nouveau"
                    }
                  >
                    <div className="content">
                      {nouveau === "Y" && (
                        <span>
                          <img src={NewIcon} alt="Nouveau" />
                        </span>
                      )}
                      {topVente === "Y" && (
                        <span>
                          <img src={BestSellerIcon} alt="Top vente" />
                        </span>
                      )}
                    </div>
                  </Tooltip>
                </div>
              </>
            )}
          </div>

          <ItemsEntity
            filter={{
              where: { id: itemId },
            }}
            quantity={quantity}
            setQuantity={setQuantityParent}
            account={account}
            referenceArticle={referenceArticle}
            code={code}
            name={name}
            assemblyGroup={assemblyGroup}
            itemId={itemId}
            itemCode={itemCode}
            oens={oens}
            dateArrivage={dateArrivage}
            application1={application1}
            application2={application2}
            application3={application3}
            brandName={brandName}
            substitutes={substitutes}
            attributes={attributes}
            detailLink={detailLink}
            showAjouterPerteVente={showAjouterPerteVente}
            handleCartPress={handleCartPress}
            addToWishList={addToWishList}
            cartItemLoading={cartItemLoading}
            itemLoading={itemLoading}
            setShowList={setShowList}
            showList={showList}
            checkIfMdds={checkIfMdds}
            article={article}
            setArticleAvecPrix={setArticleAvecPrix}
            articleAvecPrix={articleAvecPrix}
            View={CardLoader}
            NoData={Card}
            Loading={() => <Spin></Spin>}
          ></ItemsEntity>
        </div>
        <div className="article--attribute-parent">
          <div
            className={"div-horizontal " + (showList && " titre-div")}
            style={{
              position: "relative",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {showList && (
              <p className="titre" style={{ flexGrow: 1 }}>
                Caractéristiques techniques
              </p>
            )}
            {!showList && attributes && (
              <div className="journal-lumineux">
                {attributes.map((attribut, index) => (
                  <React.Fragment key={index}>
                    <label
                      style={{
                        margin:
                          index === 0 ? "0px 4px 0px 4px" : "0px 4px 0px 10px",
                      }}
                    >
                      {attribut.name + ":"}
                    </label>
                    <label style={{ margin: "0px", fontWeight: "bold" }}>
                      {attribut.value}
                    </label>
                  </React.Fragment>
                ))}
              </div>
            )}
            {!showList && !attributes && (
              <div className="div-vertical growed"></div>
            )}
            <div
              className="article--attribute--show-hide"
              onClick={() => setShowList(!showList)}
            >
              <Icon
                type={showList ? "up" : "down"}
                style={{
                  position: "absolute",
                  fontSize: 18,
                  right: 8,
                  top: 2,
                }}
              />
            </div>
          </div>
          <Attributes
            setShowList={setShowList}
            showList={showList}
            hidden={false}
            attributes={attributes}
            code={code}
          />
        </div>
      </div>
    </div>
  );
};

Normal.propTypes = {
  itemWareHouses: PropTypes.array,
  thumbnails: PropTypes.array,
};

Normal.defaultProps = {
  itemWareHouses: [],
  thumbnails: [],
};

export default Normal;
